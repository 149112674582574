/**
 * Motif v3.8.1
 * A responsive front-end framework to tell *your* story
 * http://getmotif.com
 */
/**
 * Global Stylesheet
 * The site's main (and perhaps only) stylesheet. All supporting stylesheet 
 * files are imported into and compiled from here.
 */
/*

Dynamic Responsive Image

*/
/**
 * Normalize
 * Using the Normalize.css (http://necolas.github.io/normalize.css/) with only minor modification.
 */
/*

HTML5 Display Definitions

Set the appropriate `display` definitions for new HTML5 elements.

The majority will be displayed `block`.

*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
/*

Some, however, need to be `inline-block` with an IE7 fallback.

*/
audio,
canvas,
video {
  display: inline-block;
}
.lte7 audio,
.lte7 canvas,
.lte7 video {
  display: inline;
  zoom: 1;
}
/*

Audio

From Normalize.css:

> Prevents modern browsers from displaying `audio` without controls.
> Remove excess height in iOS5 devices.

*/
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
/*

Type

*/
/*

HTML Font Size Adjust

1. Corrects text resizing oddly in IE6/7 when body `font-size` is set using
   `em` units.
2. Prevents iOS text size adjust after orientation change, without disabling
   user zoom.

*/
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}
html,
button,
input,
select,
textarea {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
abbr[title] {
  border: none;
}
i,
cite,
address {
  font-style: normal;
}
dfn {
  font-style: italic;
}
b {
  font-weight: normal;
}
strong {
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
blockquote,
menu,
ul,
ol,
dl,
table,
figure {
  /* Display & Box Model */
  margin: 1.25rem 0 0 0;
  /* Other */
  font-size: 1em;
}
menu,
ol,
ul {
  padding-left: 2.5em;
}
li ul,
li ol {
  margin-top: 0.625em;
  margin-bottom: 0.625em;
}
dd {
  margin-left: 2.5em;
}
blockquote {
  padding: 1.25em 2.5em;
}
blockquote > :first-child {
  margin-top: 0;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
small {
  font-size: 1em;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
a:focus,
a:active,
a:hover {
  outline: 0;
}
img {
  max-width: 100%;
  border: 0;
}
.lte7 img {
  -ms-interpolation-mode: bicubic;
}
svg:not(:root) {
  overflow: hidden;
}
form {
  margin: 0;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
legend {
  width: 100%;
  border: 0;
  padding: 0;
  display: block;
  white-space: normal;
}
.lte7 legend {
  margin-left: -7px;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  display: block;
}
button .lte7,
input .lte7,
select .lte7,
textarea .lte7 {
  vertical-align: middle;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  display: inline-block;
  cursor: pointer;
}
button .lte7,
html input[type="button"] .lte7,
input[type="reset"] .lte7,
input[type="submit"] .lte7 {
  overflow: visible;
  display: inline;
  zoom: 1;
}
button,
input {
  line-height: normal;
}
button[disabled],
input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  margin: 3px;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  vertical-align: top;
}
input[type="checkbox"] .lte7,
input[type="radio"] .lte7 {
  width: 13px;
  height: 13px;
}
input[type="search"] {
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-spacing: 0;
}
/* 

Spacing Helpers

Spacing helpers are quick but powerful classes that add margin or padding to 
any given element. These classes are `!important`, so they override all other 
styles.

* `p`, `m`                          =   padding, margin  
* `a`, `t`, `r`, `b`, `l`, `h`, `v` =   all, top, right, bottom, left, horizontal, vertical  
* `s`, `m`, `l`, `n`                =   small (~`5px`), medium (~`10px`), large (~`20px`), none (`0`)

For example: `.pam` means "padding, all, medium".

**Note:** These are all based on the current element's font size and the 
project's vertical rhythm (even on the sides, to provide uniform spacing). 
As such, they require `_vars.less`.

*/
.ptn,
.pvn,
.pan {
  padding-top: 0 !important;
}
.pts,
.pvs,
.pas {
  padding-top: 0.3125rem !important;
}
.ptm,
.pvm,
.pam {
  padding-top: 0.625rem !important;
}
.ptl,
.pvl,
.pal {
  padding-top: 1.25rem !important;
}
.prn,
.phn,
.pan {
  padding-right: 0 !important;
}
.prs,
.phs,
.pas {
  padding-right: 0.3125rem !important;
}
.prm,
.phm,
.pam {
  padding-right: 0.625rem !important;
}
.prl,
.phl,
.pal {
  padding-right: 1.25rem !important;
}
.pbn,
.pvn,
.pan {
  padding-bottom: 0 !important;
}
.pbs,
.pvs,
.pas {
  padding-bottom: 0.3125rem !important;
}
.pbm,
.pvm,
.pam {
  padding-bottom: 0.625rem !important;
}
.pbl,
.pvl,
.pal {
  padding-bottom: 1.25rem !important;
}
.pln,
.phn,
.pan {
  padding-left: 0 !important;
}
.pls,
.phs,
.pas {
  padding-left: 0.3125rem !important;
}
.plm,
.phm,
.pam {
  padding-left: 0.625rem !important;
}
.pll,
.phl,
.pal {
  padding-left: 1.25rem !important;
}
.mtn,
.mvn,
.man {
  margin-top: 0 !important;
}
.mts,
.mvs,
.mas {
  margin-top: 0.3125rem !important;
}
.mtm,
.mvm,
.mam {
  margin-top: 0.625rem !important;
}
.mtl,
.mvl,
.mal {
  margin-top: 1.25rem !important;
}
.mrn,
.mhn,
.man {
  margin-right: 0 !important;
}
.mrs,
.mhs,
.mas {
  margin-right: 0.3125rem !important;
}
.mrm,
.mhm,
.mam {
  margin-right: 0.625rem !important;
}
.mrl,
.mhl,
.mal {
  margin-right: 1.25rem !important;
}
.mbn,
.mvn,
.man {
  margin-bottom: 0 !important;
}
.mbs,
.mvs,
.mas {
  margin-bottom: 0.3125rem !important;
}
.mbm,
.mvm,
.mam {
  margin-bottom: 0.625rem !important;
}
.mbl,
.mvl,
.mal {
  margin-bottom: 1.25rem !important;
}
.mln,
.mhn,
.man {
  margin-left: 0 !important;
}
.mls,
.mhs,
.mas {
  margin-left: 0.3125rem !important;
}
.mlm,
.mhm,
.mam {
  margin-left: 0.625rem !important;
}
.mll,
.mhl,
.mal {
  margin-left: 1.25rem !important;
}
/*

Text Align

Simple classes to adjust your text alignment. They can be added to individual elements or on wrapping elements (an outer `div`, perhaps) to adjust the alignment of all the child elements.

*/
.align--left {
  text-align: left;
}
.align--center {
  text-align: center;
}
.align--right {
  text-align: right;
}
.align--sub {
  vertical-align: sub;
}
.align--middle {
  vertical-align: middle;
}
.align--top {
  vertical-align: top;
}
/*

Floats

Next, we have classes for floating elements.

*/
.float--left {
  float: left;
}
.float--center {
  margin-left: auto;
  margin-right: auto;
}
.float--right {
  float: right;
}
.float--none {
  float: none;
}
.clear {
  clear: both;
}
/*

"New Line"

Turns an `inline` or `inline-block` element into a `block` one.

*/
.new-line,
.media-object,
.media-object > *,
.media-object > :first-child img,
.media-object__figure img,
.media-object__desc,
.figure > a,
[class*="figure--"] > a,
.figure img,
[class*="figure--"] img,
[class*="ratio"]:before,
.input-label,
.input-hint,
.input-desc {
  display: block;
}
.group,
.breadcrumb,
.media-object--checkbox {
  /* Mixins */
}
.group:before,
.group:after,
.media-object--checkbox:before,
.media-object--checkbox:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.group:after,
.media-object--checkbox:after {
  clear: both;
}
/*

"Is Hidden"

Visually hides content in a way that leaves it accessible to screen readers. 
Like more magic, makes the visible invisible.

*/
.is-hidden,
.js .reveal__target {
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
}
.is-hidden.focusable:active,
.is-hidden.focusable:focus {
  width: auto;
  height: auto;
  margin: 0;
  clip: auto;
  overflow: visible;
  position: static;
}
body:hover .is-hidden a,
body:hover .is-hidden input,
body:hover .is-hidden button {
  display: none;
}
@media all and (max-width: 47.9375em) {
  .is-hidden-small {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .is-hidden-small.focusable:active,
  .is-hidden-small.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  body:hover .is-hidden-small a,
  body:hover .is-hidden-small input,
  body:hover .is-hidden-small button {
    display: none;
  }
}
@media all and (min-width: 48em) and (max-width: 61.9375em) {
  .is-hidden-medium {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .is-hidden-medium.focusable:active,
  .is-hidden-medium.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  body:hover .is-hidden-medium a,
  body:hover .is-hidden-medium input,
  body:hover .is-hidden-medium button {
    display: none;
  }
}
@media all and (min-width: 62em) {
  .is-hidden-large {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .is-hidden-large.focusable:active,
  .is-hidden-large.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  body:hover .is-hidden-large a,
  body:hover .is-hidden-large input,
  body:hover .is-hidden-large button {
    display: none;
  }
}
/*

"Isn't Hidden"

Undoes `.is-hidden`.

*/
.isnt-hidden,
.js .reveal__target.is-revealed {
  /* Mixins */
  width: auto;
  height: auto;
  margin: 0;
  position: relative;
  clip: auto;
}
body:hover .isnt-hidden a,
body:hover .isnt-hidden input,
body:hover .isnt-hidden button {
  display: inline-block;
}
.lte7 body:hover .isnt-hidden a,
.lte7 body:hover .isnt-hidden input,
.lte7 body:hover .isnt-hidden button {
  display: inline;
  zoom: 1;
}
/*

"Relative Container"

Exactly what it sounds like.

*/
.relative-container,
[class*="ratio"],
.js .expand__target,
.js .fade__target,
[class*="input-alert"],
.js .canvas__primary,
.js .canvas__secondary {
  position: relative;
}
.gridLines-button {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: #b4b9bc;
  color: #29333a;
  padding: 0.625rem 1.0625rem;
  border: none;
}
.gridLines-button:hover,
.gridLines-button:focus {
  background: #8c9398;
}
.gridLines-grid {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  pointer-events: none;
}
.gridLines-grid:not(.is-revealed) {
  display: none;
}
.gridLines-lines {
  display: block;
  width: 100%;
  position: relative;
  background: rgba(204, 74, 71, 0.15);
  height: 100vh;
}
.gridLines-lines:before,
.gridLines-lines:after {
  content: '';
  display: block;
  width: 1px;
  height: 100vh;
  position: absolute;
  top: 0;
  background: #CC4A47;
}
.gridLines-lines:before {
  left: 0;
}
.gridLines-lines:after {
  right: 0;
}
.gridLines-col {
  display: flex;
  align-items: stretch;
  height: 100vh;
}
.gridLines-row-l {
  height: 100%;
}
@media all and (max-width: 61.9375em) {
  .gridLines-row-l {
    display: none;
  }
}
.gridLines-row-m {
  height: 100%;
}
@media all and (max-width: 47.9375em) {
  .gridLines-row-m {
    display: none;
  }
}
@media all and (min-width: 62em) {
  .gridLines-row-m {
    display: none;
  }
}
.gridLines-row-s {
  height: 100%;
}
@media all and (min-width: 48em) {
  .gridLines-row-s {
    display: none;
  }
}
/*

Horizontal List

The basic `.list--x` module simply removes bullets from lists and inlines the list 
items, making the link `inline-block`. The idea is to give the skeleton of a 
horizontal list/navigation without adding too much style (yet).

*/
[class*="list--x"] li {
  display: inline;
}
[class*="list--x"] a {
  display: inline-block;
}
/*

Horizontal List (Forceful)

Rather than making the list `inline-block`, it floats the list elements 
(except on the smallest screen sizes, where it remains vertical). More precise
than the default Horizontal List.

*/
[class*="list--x--forceful"] li,
[class*="list--x--forceful"] a {
  display: block;
}
@media all and (min-width: 30em) {
  [class*="list--x--forceful"]:before,
  [class*="list--x--forceful"]:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  [class*="list--x--forceful"]:after {
    clear: both;
  }
}
@media all and (min-width: 48em) {
  [class*="list--x--forceful"] li {
    display: inline-block;
  }
}
/*

Link List

A basic extension of the Horizontal List, this adds some margin to the links in
the list for a more pleasant looking list of links.

**Note:** Because we are using the `[class*=""]` attribute selector, using the 
`.link-list--x` class takes advantage of the `list--x` module we declared 
earlier, as well as the Forceful variation (`.link-list--x--forceful`) 
without having to declare *both* `.list--x` and `.link-list--x` in our HTML.

````link-list
<ul class="link-list--x">
    <li>
        <a href="#">Basic Link</a>
    </li>
    <li>
        <a href="#">Basic Link</a>
    </li>
    <li>
        <a href="#">Basic Link</a>
    </li>
</ul>
````

*/
[class*="link-list--x"] a {
  margin-right: 1.875rem;
}
[class*="link-list--x"] li:last-child a {
  margin-right: 0;
}
/*

Pagination

An extension of the basic `.list--x` module that simply adds enough padding on
the links for pagination.

*/
.list--x--pgs a {
  padding: 0 0.625em;
}
.list--x--pgs a,
.list--x--pgs a:hover,
.list--x--pgs a:focus {
  color: inherit;
}
/*

Breadcrumb

Another extension of the `.list--x` module, adding a breadcrumb `label` 
and floating it and the ordered list.

````breadcrumb
<nav class="breadcrumb" role="navigation">
    <ol class="breadcrumb__list--x">
        <li>
            <a href="#">Breadcrumb Link</a>
        </li>
        <li>
            <a href="#">Breadcrumb Link</a>
        </li>
        <li>
            <a>Breadcrumb Link</a>
        </li>
    </ol>
</nav>
````

*/
.breadcrumb {
  margin-top: 1.25em;
}
.breadcrumb__label {
  margin: 0 0.53125rem 0 0;
  float: left;
}
.breadcrumb__list--x {
  margin: 0;
  float: left;
}
.breadcrumb li {
  margin-right: 0.53125rem;
}
/*

Button List

````button-list
<ul class="button-list--x">
    <li>
        <a class="btn--primary" href="#">Button</a>
    </li>
    <li>
        <a class="btn--primary is-current" href="#">Button</a>
    </li>
    <li>
        <a class="btn--primary" href="#">Button</a>
    </li>
</ul>
````

*/
[class*="button-list--x"] {
  margin-top: 0;
  overflow: hidden;
}
[class*="button-list--x"] li,
[class*="button-list--x"] a {
  display: block;
}
[class*="button-list--x"] li {
  float: left;
}
/*

Media Object

The Media Object is an image (or media-type) to the left with (typically) 
descriptive content to the right. The media object can be whatever size it 
wants, the basic format will remain the same. (Text will **not** wrap.)  This 
has any number of applications, such as a checkbox next to a label with lots 
of text.

See: [The Media Object Saves Hundreds of Lines of Code][1]

  [1]: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/


````media-object
<div class="media-object">
    <figure>
        <img src="http://placehold.it/213" alt="A media object image">
    </figure>
    <div>
        <p>He is here. No! Alderaan is peaceful. We have no weapons. You can't possibly&hellip; Kid, I've flown from one side of this galaxy to the other. I've seen a lot of strange stuff, but I've never seen anything to make me believe there's one all-powerful Force controlling everything. There's no mystical energy field that controls my destiny. It's all a lot of simple tricks and nonsense. I can't get involved! I've got work to do! It's not that I like the Empire, I hate it, but there's nothing I can do about it right now. It's such a long way from here. I'm surprised you had the courage to take the responsibility yourself. The more you tighten your grip, Tarkin, the more star systems will slip through your fingers.</p>
    </div>
</div>
````

*/
.media-object,
.media-object > *,
.media-object__desc {
  overflow: hidden;
}
.media-object--checkbox {
  /* Display & Box Model */
  display: inline-block;
  overflow: visible;
}
.media-object--checkbox > :first-child,
.media-object--checkbox .media-object__figure {
  /* Display & Box Model */
  margin-right: 0.265625rem;
}
@media all and (min-width: 30em) {
  .media-object > :first-child,
  .media-object__figure {
    max-width: 40%;
    margin-top: 0;
    margin-right: 1.0625rem;
    float: left;
  }
  .media-object > * > :first-child,
  .media-object__desc > :first-child {
    margin-top: 0;
  }
}
@media all and (min-width: 48em) {
  .media-object > :first-child,
  .media-object__figure {
    max-width: 50%;
  }
}
/*

Figures

Great for integrating images into bodies of text, the `.figure` class is 
modified by the `--left` and `--right` keywords depending on where you would 
like it placed.

````plain-media
<figure class="figure">
    <img src="http://placehold.it/800x444" alt="A full-width photo">
</figure>
````

````captioned-media
<figure class="figure">
    <img src="http://placehold.it/800x444" alt="A full-width photo with a caption">
    <figcaption class="figcaption">This caption enhances the photo above. It should only be a brief sentence or two long.</figcaption>
</figure>
````

*/
.figure,
[class*="figure--"] {
  margin-top: 1.25em;
}
@media all and (min-width: 30em) {
  [class*="figure--"] {
    margin-top: 0;
    max-width: 50%;
  }
  .figure--left,
  .figure--right {
    padding-bottom: 0.625rem;
  }
  .figure--left {
    float: left;
    padding-right: 2.125rem;
  }
  .figure--right {
    float: right;
    padding-left: 2.125rem;
  }
}
.icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: text-bottom;
  fill: currentColor;
}
.no-svg .icon {
  display: none;
}
/*

Ratios

Ratios are used primarily for objects that must scale in both width and height
proportionally, such as embedded videos.

See: (http://daverupert.com/2012/04/uncle-daves-ol-padded-box/)

The `.ratio` class (and its modifier) are placed on a wrapping element, with 
its child contents wrapped by the `.ratio__content` class.

    <div class="ratio--square">
        <div class="ratio__content">
            ...
        </div>
    </div>

For embedded videos, use the `.ratio--video` class, and it will assume an 
`iframe` is the direct child:

    <div class="ratio--video">
        <iframe src="http://youtube.com/myVidEmbed"></iframe>
    </div>

*/
[class*="ratio"]:before {
  /* Pretty */
  content: "";
}
.ratio--video:before {
  /* Display & Box Model */
  padding-top: 56.25%;
}
.ratio--1x1:before,
.ratio--square:before {
  padding-top: 100%;
}
.ratio--2x1:before {
  padding-top: 50%;
}
.ratio--1x2:before {
  padding-top: 200%;
}
.ratio__content,
.ratio--video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/*

Wrapper

This is the site-wide containing class. Put it around anything you want to be 
contained in the "site width". By default, caps off at `1150px`.

*/
.wrapper,
.gridLines-wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media all and (min-width: 77.5em) {
  .wrapper,
  .gridLines-wrapper {
    width: 80%;
    max-width: 1440px;
  }
}
/*

Animated Revealing/Hiding Block Modules

When JavaScript is enabled, these blocks are used (most easily in conjunction 
with Motif jQuery plugins) to reveal and hide blocks of content, sometimes 
accompanied by CSS3 animations.

*/
.js {
  /*
    
    Reveal Target

    `.reveal__target` is a simple class that is accessibly hidden by default.
    Add the `.is-revealed` class to show it.

        <div class="reveal__target">
            I am hidden.
        </div>
        <div class="reveal__target is-revealed">
            I am no longer hidden.
        </div>

    */
  /*
    
    Expand Target

    `.expand__target` has a maximum height of `0` by default, and once it 
    receives the `.is-expanded` class, animates in height to its maximum value. 
    This is the cheap CSS way of expanding animation, as in collapsible 
    accordions. More precise timing most likely requires JS enhancement.

        <div class="expand__target">
            I am hidden from view.
        </div>
        <div class="expand__target is-expanded">
            I have expanded into view.
        </div>

    */
  /*
    
    Fade Target

    `.fade__target` is the same as `.expand__target` (in that it grows in 
    height), but it adds opacity fading to the mix.

        <div class="fade__target">
            I am hidden.
        </div>
        <div class="fade__target is-faded">
            I have faded and expanded into view.
        </div>

    */
}
.js .reveal__target.is-revealed {
  /* Pretty */
  overflow: visible;
}
.js .expand__target,
.js .fade__target {
  max-height: 0;
  overflow: hidden;
}
.js .expand__target.is-expanded,
.js .fade__target.is-faded {
  max-height: 999px;
}
.js .expand__target.is-expanded,
.js .fade__target.is-faded,
.js .expand__target.was-expanded,
.js .fade__target.was-faded {
  -webkit-transition: all 0.65s ease-in-out;
  -moz-transition: all 0.65s ease-in-out;
  -o-transition: all 0.65s ease-in-out;
  transition: all 0.65s ease-in-out;
}
.js .fade__target {
  opacity: 0;
  filter: alpha(opacity=0);
}
.js .fade__target.is-faded {
  opacity: 1;
  filter: alpha(opacity=100);
}
.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
/*

Main Nav Module

A minor enhancement to the Reveal or Expand Target modules if added to the
main navigation bar. This snippet, on Medium screens and larger, makes sure
the expandable Nav (on smaller screens) is no longer hidden, and that the 
Menu Title (be it "Main Menu", the hamburger icon, etc.) is hidden from view.

    <nav class="nav-bar--menu" role="navigation">
        <h3 class="nav-bar__title js-expand" id="reveal-main-nav">
            <svg class="icon"><use xlink:href="#rows"></svg>
            Main Menu
        </h3>
        <ul class="nav-bar__list--x--forceful expand__target" id="main-nav-list">
            ...
        </ul>
    </nav>

*/
@media all and (min-width: 48em) {
  .js .menu__list {
    max-height: 999px;
  }
  .menu__title {
    display: none;
  }
}
/*

Tabs

The tabs module (and accompanying plugin) creates a very basic widget that 
allows embedding and basic styling.

````tabs
<section class="tabs js-tabs">
    <nav role="navigation">
        <ul>
            <li>
                <a href="#first-tab">
                    First Tab
                </a>
            </li>
            <li>
                <a href="#second-tab">
                    Second Tab
                </a>
            </li>
        </ul>
    </nav>
    <div>
        <section id="first-tab">
            <h3>First Tab</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </section>
        <section id="second-tab">
            <h3>Second Tab</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </section>
    </div>
</section>
````

*/
.js .tabs > nav + *,
.js .tabs__section {
  position: relative;
  overflow: hidden;
}
.js .tabs > nav + * > *,
.js .tab {
  display: none;
}
.js .tabs > nav + * > *.is-current,
.js .tab.is-current {
  display: block;
}
/*

Font Classes

Each font family and weight used in the site is given its own LEGO-block class
to enable quick use and modularity.

*/
.type-workhorse,
.type-workhorse-bold,
.type-workhorse-black {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.type-workhorse {
  font-weight: 400;
}
.type-workhorse-bold {
  font-weight: 700;
}
.type-workhorse-black {
  font-weight: 900;
}
.lora-bold,
h1,
h2,
h3,
h4,
h5,
h6,
.primary-heading,
.secondary-heading,
.tertiary-heading,
.quaternary-heading,
.communityCard__header--heading,
.wysiwyg h1,
.wysiwyg .canon-text,
.wysiwyg h2,
.wysiwyg .paragon-text,
.wysiwyg h3,
.wysiwyg .primer-text,
.wysiwyg h4,
.wysiwyg .normal-text,
.article__header--heading,
.article__content--sectionHeading,
.article__content--sectionSubheading,
.footnotes__header,
.issueLanding__subheader--heading,
.issueLanding__subheader--issueMeta h1,
.issueLanding__content--sectionHeading,
.issueLanding__resources--heading,
.issueLanding__community--heading,
.issue__header--heading,
.articleCard__heading,
.search__subheader--heading,
.search__content--sectionHeading {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
}
/*

Heading Styles

In Motif, typography is broken into pieces that separate skin from structure.
In this case, that means the font sizing (structure) is separate from the 
styling (skin) -- which includes font family, color, etc.

Heading styles are some of the type skins, and we start out with 
`.primary-heading` (which is also the `h`-tag default) and 
`.secondary-heading`. Add as necessary.

````primary-heading
<h1 class="primary-heading">Primary Heading</h1>
````

*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.75em;
}
h1,
h2,
h3,
h4,
h5,
h6,
.primary-heading,
.wysiwyg h1,
.wysiwyg .canon-text,
.article__header--heading,
.issueLanding__subheader--heading,
.issue__header--heading,
.search__subheader--heading {
  font-weight: 700;
}
.primary-heading,
.wysiwyg h1,
.wysiwyg .canon-text,
.article__header--heading,
.issueLanding__subheader--heading,
.issue__header--heading,
.search__subheader--heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.13157895;
  color: #29333a;
  margin-top: .75em;
}
@media all and (min-width: 62em) {
  .primary-heading,
  .wysiwyg h1,
  .wysiwyg .canon-text,
  .article__header--heading,
  .issueLanding__subheader--heading,
  .issue__header--heading,
  .search__subheader--heading {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.11666667;
  }
}
/*

Secondary Heading

````secondary-heading
<h2 class="secondary-heading">Secondary Heading</h2>
````

*/
.secondary-heading,
.wysiwyg h2,
.wysiwyg .paragon-text,
.issueLanding__resources--heading {
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 1.41176471;
  color: #29333a;
  margin-top: .75em;
}
@media all and (min-width: 62em) {
  .secondary-heading,
  .wysiwyg h2,
  .wysiwyg .paragon-text,
  .issueLanding__resources--heading {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.27083333;
  }
}
.tertiary-heading,
.wysiwyg h3,
.wysiwyg .primer-text,
.article__content--sectionHeading,
.footnotes__header,
.issueLanding__subheader--issueMeta h1,
.issueLanding__content--sectionHeading,
.issueLanding__community--heading,
.search__content--sectionHeading {
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 1.41176471;
  color: #29333a;
  margin-top: .75em;
}
@media all and (min-width: 62em) {
  .tertiary-heading,
  .wysiwyg h3,
  .wysiwyg .primer-text,
  .article__content--sectionHeading,
  .footnotes__header,
  .issueLanding__subheader--issueMeta h1,
  .issueLanding__content--sectionHeading,
  .issueLanding__community--heading,
  .search__content--sectionHeading {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.13157895;
  }
}
.quaternary-heading,
.wysiwyg h4,
.wysiwyg .normal-text,
.article__content--sectionSubheading,
.articleCard__heading {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.66666667;
  color: #29333a;
  margin-top: .75em;
}
@media all and (min-width: 62em) {
  .quaternary-heading,
  .wysiwyg h4,
  .wysiwyg .normal-text,
  .article__content--sectionSubheading,
  .articleCard__heading {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.42857143;
  }
}
/*

Body Text

Another type style, but this mimicks the default body text of the site.

````body-text
<p>This is a paragraph of text. Some of the text may be <em>emphasised</em> and some it might even be <strong>strongly emphasised</strong>. Occasionally <q>quoted text</q> may be found within a paragraph &hellip;and of course <a href="#">a link</a> may appear at any point in the text. The average paragraph contains five or six sentences although some may contain as little or one or two while others carry on for anything up to ten sentences and beyond.</p>
````

*/
/*

Unstyled List

A basic stripping of list styles.

*/
.list--unstyled,
[class*="list--x"] {
  padding-left: 0;
  list-style: none outside none;
}
/*

Blockquote

A basic blockquote.

````blockquote
<figure class="blockquote">
    <blockquote>
        At last we will reveal ourselves to the the Jedi. At last we will have 
        our revenge.
    </blockquote>
    <figcaption class="blockquote__cite">Darth Maul</figcaption>
</figure>
````

*/
.blockquote {
  padding-left: 1.0625rem;
}
.blockquote blockquote {
  padding: 0;
}
.blockquote__cite {
  display: block;
}
.blockquote__cite:before {
  content: "\2014";
}
/*

Typographic Scale

The building blocks for the site's type sizes. Almost exclusively, an element's 
font size is assigned with one of these classes. If a new size is needed, a new 
class is created here so it can be used and reused elsewhere.

The list of type sizes can grow or shrink, but be vigilant in keeping the 
number of sizes to a minimum. A nice rule of thumb is that no two sizes can one 
pixel in difference (forcing you to choose one over the other, giving you one 
class where you previously had two).

````typographic-scale
<p class="canon-text">Canon Text (38px)</p>
<p class="paragon-text">Paragon Text (30px)</p>
<p class="primer-text">Primer Text (24px)</p>
<p class="tertia-text">Tertia Text (18px)</p>
<p class="normal-text">Normal Text (14px)</p>
<p class="petite-text">Petite Text (12px)</p>
````

*/
.canon-text {
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.11666667;
}
.columbian-text {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.27083333;
}
.chilean-text {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.13157895;
}
.meridian-text {
  font-size: 34px;
  font-size: 2.125rem;
  line-height: 1.41176471;
}
.paragon-text {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.42857143;
}
.tertia-text {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.66666667;
}
.great-text {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.59090909;
}
.primer-text {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.66666667;
}
.normal-text {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625;
}
.normal-text--long,
body,
.body-text,
.input-hint,
.input-desc,
[class*="input-alert"] {
  line-height: 1.875;
}
.petite-text,
.wysiwyg h5,
.wysiwyg .petite-text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857143;
}
.pica-text,
.wysiwyg h6,
.wysiwyg .pica-text {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.66666667;
}
.nonpareil-text {
  font-size: 9px;
  font-size: 0.5625rem;
  line-height: 1.11111111;
}
/*

Long Modifiers

The smaller type sizes are given the `--long` modifier, allowing you to use
each type size with varying line heights.

    <p class="petite-text">I'm just a small bit of text, my `line-height` 
    can be tight.</p>

    <p class="petite-text--long">I'm a long paragraph, so my `line-height` 
    should be longer to enhance legibility. ... </p>

*/
.primer-text--long,
.tertia-text--long,
.normal-text--long,
.petite-text--long,
body,
.body-text,
.input-hint,
.input-desc,
[class*="input-alert"] {
  line-height: 1.5;
}
/*

Interactive Elements

*/
/*

Buttons

Default interactive button styles. This `.btn` class can be applied to `<a>`, 
`<button>`, or `<input>` elements. Button color and style classes can be combined 
with size classes, much like the type modules.

````buttons
<a class="btn" href="#">Default</a>
<a class="btn--primary" href="#">Primary</a>
<a class="btn--danger" href="#">Danger</a>
````

*/
.btn,
[class*="btn"] {
  width: auto;
  height: auto;
  margin: 0;
  line-height: 1;
  text-decoration: none;
  position: relative;
  -webkit-appearance: none;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  outline: 0;
  overflow: visible;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  background-color: #b4b9bc;
  color: #495a67;
  text-align: center;
  padding: 0.9em 1.0625rem 0.625rem;
  margin-top: 1.25em;
}
p > .btn,
p > [class*="btn"] {
  margin-top: 0;
}
.btn:hover,
[class*="btn"]:hover,
.btn:focus,
[class*="btn"]:focus,
.btn.is-current,
[class*="btn"].is-current {
  background-color: #cfd2d4;
}
/*

````tiny-buttons
<a class="btn--tiny" href="#">Tiny Button</a>
<a class="btn--tiny btn--primary" href="#">Tiny Button Primary</a>
<a class="btn--tiny btn--danger" href="#">Tiny Button Danger</a>
````

*/
/*

````small-buttons
<a class="btn--small" href="#">Small Button</a>
<a class="btn--small btn--primary" href="#">Small Button Primary</a>
<a class="btn--small btn--danger" href="#">Small Button Danger</a>
````

*/
/*

````large-buttons
<a class="btn--large" href="#">Large Button</a>
<a class="btn--large btn--primary" href="#">Large Button Primary</a>
<a class="btn--large btn--danger" href="#">Large Button Danger</a>
````

*/
.btn--tiny {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 0.3125rem 0.53125rem 0.3125rem;
}
.btn--small {
  font-size: 14px;
  font-size: 0.875rem;
}
.btn--large {
  font-size: 24px;
  font-size: 1.5rem;
}
.btn--full-width {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.btn-primary {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  background-color: #74b0bd;
  border: 3px solid #fff;
  color: #29333a;
  border-radius: 0;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.is-current {
  background-color: #0e4a61;
  color: #fff;
}
.btn-secondary,
.articleCard__content--cta {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  background-color: #fff;
  border: 3px solid #177da4;
  color: #156d8e;
  border-radius: 0;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.is-current,
.articleCard__content--cta:hover,
.articleCard__content--cta:focus,
.articleCard__content--cta.is-current {
  background-color: #f2f2f2;
  border-color: #115b77;
  color: #0e4b62;
}
/*

Link Styles

Very basic stuff. The primary link color can be altered in the `_config.less` 
file. Hover, focus, and current states are accounted for.

*/
a,
.link,
.list--x--pgs a[href] {
  color: #177da4;
}
a:hover,
.link:hover,
a:focus,
.link:focus,
a.is-current,
.link.is-current,
.list--x--pgs a[href]:hover,
.list--x--pgs a[href]:focus,
.list--x--pgs a[href].is-current {
  color: #156d8e;
}
/*

Unlink

Basic class to remove some link styling.

@TODO: Put in `_presentational.less` file?

*/
.unlink {
  text-decoration: none;
}
/*

Skip-To Link

*/
.links__skip-to {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}
.links__skip-to:not(:focus) {
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
}
.links__skip-to:not(:focus).focusable:active,
.links__skip-to:not(:focus).focusable:focus {
  width: auto;
  height: auto;
  margin: 0;
  clip: auto;
  overflow: visible;
  position: static;
}
body:hover .links__skip-to:not(:focus) a,
body:hover .links__skip-to:not(:focus) input,
body:hover .links__skip-to:not(:focus) button {
  display: none;
}
/*

Forms

*/
/*

Basic Form Elements

Inputs and textareas are set to `width: 100%` to adapt to the width of their 
wrapper. (Great for grids.) Select boxes are set to the width of their content,
maxing out at `100%`.

Almost all the form elements in Motif are best used inside a `label`.

    <label>
        Text Field
        <input type="text" name="text" required>
    </label>

*/
input,
textarea {
  width: 100%;
  font-size: 1em;
  padding: 0.3125rem 0.25rem;
  outline: none;
}
input:hover,
textarea:hover,
input:focus,
textarea:focus,
input:active,
textarea:active,
input.is-current,
textarea.is-current {
  z-index: 1;
}
input[type="checkbox"],
input[type="radio"] {
  width: auto;
}
.lte7 input[type="checkbox"],
.lte7 input[type="radio"] {
  width: 13px;
}
textarea {
  height: 5rem;
}
select {
  width: auto;
  max-width: 100%;
  font-size: 0.8125em;
}
/*

Input Label Elements

Child form elements of the `.input-label` class are given a little bit of 
spacing to separate them from the label text. (See also `.input-label` in the
form `_type.less` file.)

````input-label-elements
<label class="input-label">
    Text Field
    <input type="text" name="text" placeholder="Placeholder text" required>
</label>
<label class="input-label">
    Textarea
    <textarea placeholder="Placeholder text"></textarea>
</label>
<label class="input-label">
    Select
    <select>
        <option>Placeholder text</option>
    </select>
</label>
````

*/
.input-label input,
.input-label textarea,
.input-label select {
  margin-top: 0.625em;
  margin-bottom: 0.625em;
}
/*

Input Widths

While you are encouraged to use the responsive grid system in conjunction with 
your form styles, sometimes it's useful to have form field widths that are 
simply consistent across viewports. These are those.

    <label class="input-label">
        Text Field
        <input class="input--xs" type="text" name="text" required>
    </label>

*/
.input--eighth {
  width: 12.5%;
}
.input--quarter {
  width: 25%;
}
.input--half {
  width: 50%;
}
.input--xxxs {
  max-width: 1.875rem;
}
.input--xxs {
  max-width: 3.125rem;
}
.input--xs {
  max-width: 6.25rem;
}
.input--s {
  max-width: 10.9375rem;
}
.input--m {
  max-width: 15.625rem;
}
.input--l {
  max-width: 21.875rem;
}
/*

Form Modules

````attached-label-before
<label class="attached-label">
    <small class="label">$</small>
    <input type="num" id="attached-label-before" required>
</label>
````

````attached-label-after
<label class="attached-label">
    <input type="text" id="attached-label-after" required>
    <small class="label">.com</small>
</label>
````

````attached-label-before-and-after
<label class="attached-label">
    <small class="label">www.</small>
    <input type="text" id="attached-label" placeholder="domain" required>
    <small class="label">.com</small>
</label>
````

````attached-label-submit
<form class="attached-label">
    <label class="is-hidden" for="attached-label-submit">Search</label>
    <input type="search" id="attached-label-submit" required>
    <div>
        <button type="submit" class="btn--primary">
            <svg class="icon"><use xlink:href="#search"></svg>
            <b class="is-hidden">Submit</b>
        </button>
    </div>
</form>
````

*/
.attached-label {
  display: table;
  overflow: hidden;
}
.attached-label > * {
  margin-top: 0;
  display: table-cell;
  vertical-align: middle;
}
.attached-label > * > :first-child {
  margin-top: 0;
}
.attached-label [class*="btn"] {
  display: block;
  width: 100%;
}
/*

Input Label

The `.input-label` class should be added to form labels that want a little 
extra styling for the label text.

(You may need to do some fancy things with hidden or altered labels, which is
why these styles aren't added to the `label` element by default.)

    <label class="input-label">
        Text Field
        <input type="text" name="text" required>
    </label>

There are also input descriptions, which go directly underneath the label text,
and input hints that typically go underneath the input field.

    <label class="input-label">
        Text Field
        
        <small class="input-desc">
            This is a small description of the field.
        </small>
        
        <input type="text" name="text" required>
        
        <strong class="input-hint">
            This is an important hint.
        </strong>
    </label>

*/
.input-label {
  padding-top: 1.25em;
}
.input-hint {
  color: #8c9398;
  margin-top: 0.3125em;
}
.input-desc {
  margin-top: 0em;
}
/*

Fieldset

A simple class to add to fieldsets that want a little more spacing.

*/
.fieldset {
  margin-top: 1.25em;
  padding-bottom: 1.25em;
}
/*

Checkbox List

Lists of radio or checkbox inputs should typically be just that: lists. In 
Motif, you're encouraged to mark up your input lists as semantically as 
possible, meaning a fieldset, with a legend, and a list of inputs:

    <fieldset>
        <legend>
            <b class="input-label">This is a question for a radio list?</b>
        </legend>

        <ul class="checkbox-list">
            <li>
                <label class="media-object">
                    <input class="media-object__figure"
                     type="radio"
                     name="radioList"
                     value="Yes">

                    <b class="media-object__desc">
                        Yes
                    </b>
                </label>
            </li>
            <li>
                <label class="media-object">
                    <input class="media-object__figure"
                     type="radio"
                     name="radioList"
                     value="No">

                    <b class="media-object__desc">
                        No
                    </b>
                </label>
            </li>
        </ul>
    </fieldset>

*/
.checkbox-list {
  margin-top: 0.625em;
}
legend + .checkbox-list {
  margin-top: 0em;
}
legend .input-label {
  padding-bottom: 0.3125em;
}
legend + .input-desc {
  /* Positioning */
  position: relative;
  top: -0.3125rem;
}
/*

Form Validation

*/
/*

Input States

A couple of classes added to inputs (typically by a JS Validation plugin) that
alter the child elements to convey states of success and error.

    <label class="input-label is-erroneous">
        Text Field
        <input type="text" name="text" required>
    </label>

*/
.is-successful input,
.is-successful textarea,
.is-successful select {
  border-color: #72bc44;
}
.is-erroneous input,
.is-erroneous textarea,
.is-erroneous select {
  border-color: #CC4A47;
}
/*

Form Messaging

Within labels, there are input alerts that apply to individual fields.

    <label class="input-label is-erroneous">
        Text Field
        <input type="text" name="text" required>
        <strong class="input-alert--error">
            This field is required.
        </strong>
    </label>

*/
[class*="input-alert"] {
  /* Display & Box Model */
  display: inline-block;
  /* Pretty */
  color: #156d8e;
}
.input-alert--warning {
  color: #ec8e04;
}
.input-alert--error {
  color: #CC4A47;
}
.input-alert--success {
  color: #72bc44;
}
/*

Responsive Grid

The default responsive grid in Motif is built with a few rules in mind:

1. Each row of columns must be wrapped in a row element
2. There's no need to specify if a column is first or last

With that in mind, there is a basic syntax to learn for what classes to use.
Our breakpoints are classified as:

- Base
- Small
- Medium
- Large
- Extra Large

Our default responsive grid system only uses 3 grids to cover all of our bases:

- `grid, small` covers Base and Small viewports, but cuts off at Medium
- `grid, medium` covers only Medium
- `grid, large` starts at Large and covers Extra Large as well (there is no max
cutoff value for `grid, large`)

Understanding that, the syntax should be easy to understand. In these examples,
we will talk about `grid, small`:

- **Rows**: `.gs-row` (Grid, Small, Row)
- **Columns**: `.gcs` (Grid, Column, Small)
- **Column Width**: `.gs-half` (Grid, Small, Half-Width)

    <div class="gs-row">
        <div class="gcs gs-half">...</div>
        <div class="gcs gs-half">...</div>
    </div>

However, to be slightly more succinct, you can use the condensed column syntax:

- **Columns (Condensed)**: `.gcs-half` (Grid, Column, Small, Half-Width)

    <div class="gs-row">
        <div class="gcs-half">...</div>
        <div class="gcs-half">...</div>
    </div>

In brief, the way the grid works is that `.gcs` floats the element to the left
(by default), and adds padding to both the left and the right. `-half` gives
it a width of `50%`. The combined padding of the two columns bumped up against 
each other form the full "gutter". The `.gs-row` then clears the floats and has
a *negative* margin on both the left and the right that pops the columns out to
the sides the exact width of their paddings, meaning the column content lines 
up with the site boundaries on the left and right without the need to specify 
"first" or "last" column classes.

To use this responsively, then, you just add the classes by breakpoint:

    <div class="gs-row gm-row gl-row">
        <div class="gcs-half gcm-third gcl-quarter">
            ...
        </div>
        <div class="gcs-half gcm-two-thirds gcl-three-quarters">
            ...
        </div>
    </div>

By default, the column breakdown of each grid breakpoint:

- `grid, small`: 4 columns
- `grid, medium`: 9 columns
- `grid, large`: 12 columns

For the larger grids, the classes follow the numbers closely...

    - `.gcl-10of12
    - `.gcm-4of9`

...in just about every combination (based on their total column number), but 
all of the grids also have a few "fuzzy" shortcuts as well:

    - `half`
    - `third`
    - `quarter`
    - `three-quarters`
    - `two-thirds`

*/
.g-halves,
.g-thirds,
.g-quarters {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  /* Pretty */
  letter-spacing: -0.31em;
  text-rendering: optimizespeed;
}
.g-halves:before,
.g-thirds:before,
.g-quarters:before,
.g-halves:after,
.g-thirds:after,
.g-quarters:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.g-halves:after,
.g-thirds:after,
.g-quarters:after {
  clear: both;
}
ul.g-halves,
ul.g-thirds,
ul.g-quarters {
  padding-left: 0;
  list-style: none outside none;
}
.g-halves > *,
.g-thirds > *,
.g-quarters > * {
  display: inline;
  float: left;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  /* Display & Box Model */
  display: inline-block;
  /* Positioning */
  float: none;
  /* Pretty */
  letter-spacing: normal;
  word-spacing: normal;
  vertical-align: top;
  text-rendering: auto;
}
.g-halves > * {
  width: 50%;
}
.g-thirds > * {
  width: 33.33333%;
}
.g-quarters > * {
  width: 25%;
}
.opera-only :-o-prefocus,
.g-thirds {
  word-spacing: -0.43em;
}
/**
 * Mobile Grid
 */
@media all and (max-width: 47.9375em) {
  [class*="gs-row"],
  .gs-quarters,
  .gs-thirds,
  .gs-halves,
  .gridLines-row-s {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  [class*="gs-row"]:before,
  [class*="gs-row"]:after,
  .gs-quarters:before,
  .gs-quarters:after,
  .gs-thirds:before,
  .gs-thirds:after,
  .gs-halves:before,
  .gs-halves:after,
  .gridLines-row-s:before,
  .gridLines-row-s:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  [class*="gs-row"]:after,
  .gs-quarters:after,
  .gs-thirds:after,
  .gs-halves:after,
  .gridLines-row-s:after {
    clear: both;
  }
  ul[class*="gs-row"],
  ul.gs-quarters,
  ul.gs-thirds,
  ul.gs-halves,
  ul.gridLines-row-s {
    padding-left: 0;
    list-style: none outside none;
  }
  .gs-row--rtl > [class*="gcs"] {
    float: right;
  }
  .gs-row--ltr > [class*="gcs"] {
    float: left;
  }
  [class*="gcs"],
  .gs-quarters > *,
  .gs-thirds > *,
  .gs-halves > * {
    display: inline;
    float: left;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .gs-quarter,
  .gcs-quarter,
  .gs-quarters > * {
    width: 25%;
  }
  .gs-third,
  .gcs-third,
  .gs-thirds > * {
    width: 33.33333%;
  }
  .gs-half,
  .gcs-half,
  .gs-halves > * {
    width: 50%;
  }
  .gs-two-thirds,
  .gcs-two-thirds {
    width: 66.66667%;
  }
  .gs-three-quarters,
  .gcs-three-quarters {
    width: 75%;
  }
  .gs-full,
  .gcs-full {
    width: 100%;
  }
  .gs-row--flex,
  .gs-quarters,
  .gs-thirds,
  .gs-halves {
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
  }
  .gs-row--flex > [class*="gcs"],
  .gs-quarters > *,
  .gs-thirds > *,
  .gs-halves > * {
    float: none;
    display: inline-block;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .opera-only :-o-prefocus,
  .gs-row--flex,
  .gs-quarters,
  .gs-thirds,
  .gs-halves {
    word-spacing: -0.43em;
  }
}
/**
 * Tablet Grid
 */
@media all and (min-width: 48em) and (max-width: 61.9375em) {
  [class*="gm-row"],
  .gm-quarters,
  .gm-thirds,
  .gm-halves,
  .gridLines-row-m {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  [class*="gm-row"]:before,
  [class*="gm-row"]:after,
  .gm-quarters:before,
  .gm-quarters:after,
  .gm-thirds:before,
  .gm-thirds:after,
  .gm-halves:before,
  .gm-halves:after,
  .gridLines-row-m:before,
  .gridLines-row-m:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  [class*="gm-row"]:after,
  .gm-quarters:after,
  .gm-thirds:after,
  .gm-halves:after,
  .gridLines-row-m:after {
    clear: both;
  }
  ul[class*="gm-row"],
  ul.gm-quarters,
  ul.gm-thirds,
  ul.gm-halves,
  ul.gridLines-row-m {
    padding-left: 0;
    list-style: none outside none;
  }
  .gm-row--rtl > [class*="gcm"] {
    float: right;
  }
  .gm-row--ltr > [class*="gcm"] {
    float: left;
  }
  [class*="gcm"],
  .gm-quarters > *,
  .gm-thirds > *,
  .gm-halves > * {
    display: inline;
    float: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .gm-quarter,
  .gcm-quarter,
  [class*="m-2of8"],
  .gm-quarters > * {
    width: 25%;
  }
  .gm-third,
  .gcm-third,
  [class*="m-3of9"],
  [class*="m-2of6"],
  .gm-thirds > * {
    width: 33.33333%;
  }
  .gm-half,
  .gcm-half,
  [class*="m-4of8"],
  [class*="m-3of6"],
  .gm-halves > * {
    width: 50%;
  }
  .gm-two-thirds,
  .gcm-two-thirds,
  [class*="m-6of9"],
  [class*="m-4of6"] {
    width: 66.66667%;
  }
  .gm-three-quarters,
  .gcm-three-quarters,
  [class*="m-6of8"] {
    width: 75%;
  }
  .gm-full,
  .gcm-full {
    width: 100%;
  }
  [class*="m-8of9"] {
    width: 88.88889%;
  }
  [class*="m-7of9"] {
    width: 77.77778%;
  }
  [class*="m-5of9"] {
    width: 55.55556%;
  }
  [class*="m-4of9"] {
    width: 44.44444%;
  }
  [class*="m-2of9"] {
    width: 22.22222%;
  }
  [class*="m-1of9"] {
    width: 11.11111%;
  }
  [class*="m-7of8"] {
    width: 87.5%;
  }
  [class*="m-5of8"] {
    width: 62.5%;
  }
  [class*="m-3of8"] {
    width: 37.5%;
  }
  [class*="m-1of8"] {
    width: 12.5%;
  }
  [class*="m-6of7"] {
    width: 85.71429%;
  }
  [class*="m-5of7"] {
    width: 71.42857%;
  }
  [class*="m-4of7"] {
    width: 57.14286%;
  }
  [class*="m-3of7"] {
    width: 42.85714%;
  }
  [class*="m-2of7"] {
    width: 28.57143%;
  }
  [class*="m-1of7"] {
    width: 14.28571%;
  }
  [class*="m-5of6"] {
    width: 83.33333%;
  }
  [class*="m-1of6"] {
    width: 16.66667%;
  }
  [class*="m-4of5"] {
    width: 80%;
  }
  [class*="m-3of5"] {
    width: 60%;
  }
  [class*="m-2of5"] {
    width: 40%;
  }
  [class*="m-1of5"] {
    width: 20%;
  }
  .gm-row--flex,
  .gm-quarters,
  .gm-thirds,
  .gm-halves {
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
  }
  .gm-row--flex > [class*="gcm"],
  .gm-quarters > *,
  .gm-thirds > *,
  .gm-halves > * {
    float: none;
    display: inline-block;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .opera-only :-o-prefocus,
  .gm-row--flex,
  .gm-quarters,
  .gm-thirds,
  .gm-halves {
    word-spacing: -0.43em;
  }
}
/**
 * Desktop Grid
 */
/*

````desktop-grid
<div class="gl-quarters">
    <div>
        <div class="block">Quarter</div>
    </div>
    <div>
        <div class="block">Quarter</div>
    </div>
    <div>
        <div class="block">Quarter</div>
    </div>
    <div>
        <div class="block">Quarter</div>
    </div>
</div>
<div class="gl-thirds">
    <div>
        <div class="block">Third</div>
    </div>
    <div>
        <div class="block">Third</div>
    </div>
    <div>
        <div class="block">Third</div>
    </div>
</div>
<div class="gl-halves">
    <div>
        <div class="block">Half</div>
    </div>
    <div>
        <div class="block">Half</div>
    </div>
</div>
<div class="gl-row">
    <div class="gcl-two-thirds">
        <div class="block">Two Thirds</div>
    </div>
    <div class="gcl-third">
        <div class="block">Third</div>
    </div>
</div>
<div class="gl-row">
    <div class="gcl-three-quarters">
        <div class="block">Three Quarters</div>
    </div>
    <div class="gcl-quarter">
        <div class="block">Quarter</div>
    </div>
</div>
````

*/
@media all and (min-width: 62em) {
  [class*="gl-row"],
  .gl-quarters,
  .gl-thirds,
  .gl-halves,
  .gridLines-row-l {
    margin-left: -1.0625rem;
    margin-right: -1.0625rem;
  }
  [class*="gl-row"]:before,
  [class*="gl-row"]:after,
  .gl-quarters:before,
  .gl-quarters:after,
  .gl-thirds:before,
  .gl-thirds:after,
  .gl-halves:before,
  .gl-halves:after,
  .gridLines-row-l:before,
  .gridLines-row-l:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  [class*="gl-row"]:after,
  .gl-quarters:after,
  .gl-thirds:after,
  .gl-halves:after,
  .gridLines-row-l:after {
    clear: both;
  }
  ul[class*="gl-row"],
  ul.gl-quarters,
  ul.gl-thirds,
  ul.gl-halves,
  ul.gridLines-row-l {
    padding-left: 0;
    list-style: none outside none;
  }
  .gl-row--rtl > [class*="gcl"] {
    float: right;
  }
  .gl-row--ltr > [class*="gcl"] {
    float: left;
  }
  [class*="gcl"],
  .gl-quarters > *,
  .gl-thirds > *,
  .gl-halves > * {
    display: inline;
    float: left;
    padding-left: 1.0625rem;
    padding-right: 1.0625rem;
  }
  .gl-quarter,
  .gcl-quarter,
  [class*="l-3of12"],
  [class*="l-2of8"],
  .gl-quarters > * {
    width: 25%;
  }
  .gl-third,
  .gcl-third,
  [class*="l-4of12"],
  [class*="l-3of9"],
  [class*="l-2of6"],
  .gl-thirds > * {
    width: 33.33333%;
  }
  .gl-half,
  .gcl-half,
  [class*="l-6of12"],
  [class*="l-5of10"],
  [class*="l-4of8"],
  [class*="l-3of6"],
  .gl-halves > * {
    width: 50%;
  }
  .gl-two-thirds,
  .gcl-two-thirds,
  [class*="l-8of12"],
  [class*="l-6of9"],
  [class*="l-4of6"] {
    width: 66.66667%;
  }
  .gl-three-quarters,
  .gcl-three-quarters,
  [class*="l-9of12"],
  [class*="l-6of8"] {
    width: 75%;
  }
  .gl-full,
  .gcl-full {
    width: 100%;
  }
  [class*="l-11of12"] {
    width: 91.66667%;
  }
  [class*="l-10of12"] {
    width: 83.33333%;
  }
  [class*="l-7of12"] {
    width: 58.33333%;
  }
  [class*="l-5of12"] {
    width: 41.66667%;
  }
  [class*="l-2of12"] {
    width: 16.66667%;
  }
  [class*="l-1of12"] {
    width: 8.33333%;
  }
  [class*="l-10of11"] {
    width: 90.90909%;
  }
  [class*="l-9of11"] {
    width: 81.81818%;
  }
  [class*="l-8of11"] {
    width: 72.72727%;
  }
  [class*="l-7of11"] {
    width: 63.63636%;
  }
  [class*="l-6of11"] {
    width: 54.54545%;
  }
  [class*="l-5of11"] {
    width: 45.45455%;
  }
  [class*="l-4of11"] {
    width: 36.36364%;
  }
  [class*="l-3of11"] {
    width: 27.27273%;
  }
  [class*="l-2of11"] {
    width: 18.18182%;
  }
  [class*="l-1of11"] {
    width: 9.09091%;
  }
  [class*="l-9of10"] {
    width: 90%;
  }
  [class*="l-8of10"] {
    width: 80%;
  }
  [class*="l-7of10"] {
    width: 70%;
  }
  [class*="l-6of10"] {
    width: 60%;
  }
  [class*="l-4of10"] {
    width: 40%;
  }
  [class*="l-3of10"] {
    width: 30%;
  }
  [class*="l-2of10"] {
    width: 20%;
  }
  [class*="l-1of10"] {
    width: 10%;
  }
  [class*="l-8of9"] {
    width: 88.88889%;
  }
  [class*="l-7of9"] {
    width: 77.77778%;
  }
  [class*="l-5of9"] {
    width: 55.55556%;
  }
  [class*="l-4of9"] {
    width: 44.44444%;
  }
  [class*="l-2of9"] {
    width: 22.22222%;
  }
  [class*="l-1of9"] {
    width: 11.11111%;
  }
  [class*="l-7of8"] {
    width: 87.5%;
  }
  [class*="l-5of8"] {
    width: 62.5%;
  }
  [class*="l-3of8"] {
    width: 37.5%;
  }
  [class*="l-1of8"] {
    width: 12.5%;
  }
  [class*="l-6of7"] {
    width: 85.71429%;
  }
  [class*="l-5of7"] {
    width: 71.42857%;
  }
  [class*="l-4of7"] {
    width: 57.14286%;
  }
  [class*="l-3of7"] {
    width: 42.85714%;
  }
  [class*="l-2of7"] {
    width: 28.57143%;
  }
  [class*="l-1of7"] {
    width: 14.28571%;
  }
  [class*="l-5of6"] {
    width: 83.33333%;
  }
  [class*="l-1of6"] {
    width: 16.66667%;
  }
  [class*="l-4of5"] {
    width: 80%;
  }
  [class*="l-3of5"] {
    width: 60%;
  }
  [class*="l-2of5"] {
    width: 40%;
  }
  [class*="l-1of5"] {
    width: 20%;
  }
  /*

Media Grid

The Media Grid allows you to place a grid of photos or videos (catalog or 
retail products, etc.) without floating them, alleviating the need to concern 
yourself with clearing the floats with rows should an object extend further 
than another. Also, because they are not floating, it allows you to align 
the entire group center, making sure any "orphaned" grid entries are centered.

The syntax is simply the same as the regular responsive grid system, except
the row class has a `--flex` modifier.

````media-grid
<ul class="gs-halves gm-thirds gl-quarters">
    <li>
        <figure class="figure">
            <img src="http://placehold.it/400">
            <figcaption>Alderaan is peaceful. We have no weapons.</figcaption>
        </figure>
    </li>
    <li>
        <figure class="figure">
            <img src="http://placehold.it/400">
        </figure>
    </li>
    <li>
        <figure class="figure">
            <img src="http://placehold.it/400">
            <figcaption>Alderaan is peaceful. We have no weapons.</figcaption>
        </figure>
    </li>
    <li>
        <figure class="figure">
            <img src="http://placehold.it/400">
        </figure>
    </li>
    <li>
        <figure class="figure">
            <img src="http://placehold.it/400">
            <figcaption>Alderaan is peaceful. We have no weapons.</figcaption>
        </figure>
    </li>
    <li>
        <figure class="figure">
            <img src="http://placehold.it/400">
        </figure>
    </li>
    <li>
        <figure class="figure">
            <img src="http://placehold.it/400">
        </figure>
    </li>
</ul>
````

*/
  .gl-row--flex,
  .gl-quarters,
  .gl-thirds,
  .gl-halves {
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
  }
  .gl-row--flex > [class*="gcl"],
  .gl-quarters > *,
  .gl-thirds > *,
  .gl-halves > * {
    float: none;
    display: inline-block;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .opera-only :-o-prefocus,
  .gl-row--flex,
  .gl-quarters,
  .gl-thirds,
  .gl-halves {
    word-spacing: -0.43em;
  }
}
/*

Off-Canvas Trigger (Small Screens Only)

The Off-Canvas Trigger is a simple way of pushing a column of content
off-canvas and sliding it on upon trigger. In this case, the `is-active` 
class is placed on the `.off-canvas` wrapping element so that it can 
adjust both child elements.

@TODO: Adjust for IE9?
@TODO: Show better example w/ Reveal plugin

    <div class="off-canvas--left">
        <div class="canvas__primary">
            ...
        </div>
        <div class="canvas__secondary">
            ...
        </div>
    </div>

*/
.js [class*="off-canvas--"] {
  /* Positioning */
  position: relative;
  /* Display & Box Model */
  overflow: hidden;
}
.js [class*="off-canvas--"].is-active > .canvas__primary {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.js [class*="off-canvas--"].is-active > .canvas__primary,
.js [class*="off-canvas--"].was-active > .canvas__primary,
.js [class*="off-canvas--"].is-active > .canvas__secondary,
.js [class*="off-canvas--"].was-active > .canvas__secondary {
  -webkit-transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.js .canvas__secondary {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.js .off-canvas--left > .canvas__primary,
.js .off-canvas--right > .canvas__primary,
.js .off-canvas--left > .canvas__secondary,
.js .off-canvas--right > .canvas__secondary {
  min-height: 100vh;
}
.js .off-canvas--left > .canvas__secondary,
.js .off-canvas--right > .canvas__secondary {
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}
.js .off-canvas--left.is-active,
.js .off-canvas--right.is-active,
.js .off-canvas--left.is-active > .canvas_primary,
.js .off-canvas--right.is-active > .canvas_primary {
  height: 100vh;
  overflow: hidden;
}
.js .off-canvas--right.is-active > .canvas__secondary {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.js .off-canvas--right > .canvas__primary {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  /* Positioning */
  float: left;
  /* Display & Box Model */
  margin-left: -100%;
}
.js .off-canvas--right > .canvas__secondary {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* Positioning */
  float: right;
  /* Display & Box Model */
  margin-right: -100%;
}
.js .off-canvas--left.is-active > .canvas__secondary {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.js .off-canvas--left > .canvas__primary {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  /* Positioning */
  float: right;
  /* Display & Box Model */
  margin-right: -100%;
}
.js .off-canvas--left > .canvas__secondary {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* Positioning */
  float: left;
  /* Display & Box Model */
  margin-left: -100%;
}
.js .off-canvas--top,
.js .off-canvas--bottom {
  /* Positioning */
  position: relative;
  /* Display & Box Model */
  min-height: 100vh;
}
.js .off-canvas--top.is-active,
.js .off-canvas--bottom.is-active {
  height: 100vh;
  overflow: hidden;
}
.js .off-canvas--top.is-active > .canvas__primary,
.js .off-canvas--bottom.is-active > .canvas__primary {
  /* Positioning */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  /* Display & Box Model */
  height: 100vh;
  overflow: hidden;
}
.js .off-canvas--top.is-active > .canvas__secondary,
.js .off-canvas--bottom.is-active > .canvas__secondary {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  /* Display & Box Model */
  overflow: auto;
}
.js .off-canvas--top > .canvas__secondary,
.js .off-canvas--bottom > .canvas__secondary {
  /* Positioning */
  position: absolute;
  left: 0;
  right: 0;
  /* Display & Box Model */
  width: 100%;
  height: 80vh;
  overflow: hidden;
}
.js .off-canvas--top.is-active > .canvas__primary {
  -webkit-transform: translate3d(0, 80vh, 0);
  -moz-transform: translate3d(0, 80vh, 0);
  -o-transform: translate3d(0, 80vh, 0);
  -ms-transform: translate3d(0, 80vh, 0);
  transform: translate3d(0, 80vh, 0);
}
.js .off-canvas--top > .canvas__secondary {
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -o-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  /* Positioning */
  top: 0;
}
.js .off-canvas--bottom.is-active > .canvas__primary {
  -webkit-transform: translate3d(0, -80vh, 0);
  -moz-transform: translate3d(0, -80vh, 0);
  -o-transform: translate3d(0, -80vh, 0);
  -ms-transform: translate3d(0, -80vh, 0);
  transform: translate3d(0, -80vh, 0);
}
.js .off-canvas--bottom > .canvas__secondary {
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -o-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  /* Positioning */
  bottom: 0;
}
.js .canvas__primary,
.js .canvas__secondary {
  /* Display & Box Model */
  width: 100%;
}
.js .canvas__primary {
  z-index: 1;
}
.js .canvas__secondary {
  z-index: 2;
}
@media all and (min-width: 48em) {
  .js [class*="off-canvas--"].is-active > .canvas__primary,
  .js [class*="off-canvas--"].was-active > .canvas__primary,
  .js [class*="off-canvas--"].is-active > .canvas__secondary,
  .js [class*="off-canvas--"].was-active > .canvas__secondary {
    -webkit-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .js .off-canvas--right > .canvas__secondary,
  .js .off-canvas--left > .canvas__secondary {
    width: 70%;
  }
  .js .off-canvas--right.is-active > .canvas__primary {
    -webkit-transform: translate3d(30%, 0, 0);
    -moz-transform: translate3d(30%, 0, 0);
    -o-transform: translate3d(30%, 0, 0);
    -ms-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  .js .off-canvas--right > .canvas__secondary {
    margin-right: -70%;
  }
  .js .off-canvas--left.is-active > .canvas__primary {
    -webkit-transform: translate3d(-30%, 0, 0);
    -moz-transform: translate3d(-30%, 0, 0);
    -o-transform: translate3d(-30%, 0, 0);
    -ms-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  .js .off-canvas--left > .canvas__secondary {
    margin-left: -70%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas--right > .canvas__secondary,
  .js .off-canvas--left > .canvas__secondary {
    width: 60%;
  }
  .js .off-canvas--right.is-active > .canvas__primary {
    -webkit-transform: translate3d(40%, 0, 0);
    -moz-transform: translate3d(40%, 0, 0);
    -o-transform: translate3d(40%, 0, 0);
    -ms-transform: translate3d(40%, 0, 0);
    transform: translate3d(40%, 0, 0);
  }
  .js .off-canvas--right > .canvas__secondary {
    margin-right: -60%;
  }
  .js .off-canvas--left.is-active > .canvas__primary {
    -webkit-transform: translate3d(-40%, 0, 0);
    -moz-transform: translate3d(-40%, 0, 0);
    -o-transform: translate3d(-40%, 0, 0);
    -ms-transform: translate3d(-40%, 0, 0);
    transform: translate3d(-40%, 0, 0);
  }
  .js .off-canvas--left > .canvas__secondary {
    margin-left: -60%;
  }
}
.js [class*="slide-canvas--"] {
  -webkit-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  /* Positioning */
  position: fixed;
  z-index: 110;
  /* Display & Box Model */
  overflow: auto;
  overflow-x: hidden;
}
.js [class*="slide-canvas--"].is-revealed {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slide-canvas--left,
.slide-canvas--right {
  /* Positioning */
  top: 0;
  bottom: 0;
  /* Display & Box Model */
  width: 80%;
}
@media all and (min-width: 48em) {
  .slide-canvas--left,
  .slide-canvas--right {
    width: 33%;
  }
}
@media all and (min-width: 62em) {
  .slide-canvas--left,
  .slide-canvas--right {
    width: 25%;
  }
}
@media all and (min-width: 77.5em) {
  .slide-canvas--left,
  .slide-canvas--right {
    width: 20%;
  }
}
.slide-canvas--left {
  -webkit-transform: translate3d(-100%, 0, 0);
  -moz-transform: translate3d(-100%, 0, 0);
  -o-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  /* Positioning */
  left: 0;
}
.slide-canvas--right {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  /* Positioning */
  right: 0;
}
.slide-canvas--top,
.slide-canvas--bottom {
  /* Positioning */
  left: 0;
  right: 0;
  /* Display & Box Model */
  height: 90%;
}
@media all and (min-width: 48em) {
  .slide-canvas--top,
  .slide-canvas--bottom {
    height: 33%;
  }
}
@media all and (min-width: 62em) {
  .slide-canvas--top,
  .slide-canvas--bottom {
    height: 25%;
  }
}
@media all and (min-width: 77.5em) {
  .slide-canvas--top,
  .slide-canvas--bottom {
    height: 20%;
  }
}
.slide-canvas--top {
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -o-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  /* Positioning */
  top: 0;
}
.slide-canvas--bottom {
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -o-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  /* Positioning */
  bottom: 0;
}
.overflow-list {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.overflow-list__item {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  position: relative;
}
@media all and (min-width: 48em) {
  .overflow-list--until-medium {
    overflow: visible;
    white-space: normal;
  }
}
@media all and (min-width: 62em) {
  .overflow-list--until-large {
    overflow: visible;
    white-space: normal;
  }
}
@media all and (min-width: 77.5em) {
  .overflow-list--until-x-large {
    overflow: visible;
    white-space: normal;
  }
}
/*

Tables

*/
/*

Default Tables

Simple, clean default styles. Just mark it up like a normal table.

Undoes table to more of a definition list on smaller viewports.

See: http://codepen.io/aarongustafson/pen/ucJGv

````tables
<table>
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Language</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td data-th="#">1</td>
            <td data-th="First Name">Some</td>
            <td data-th="Last Name">One</td>
            <td data-th="Language">English</td>
        </tr>
        <tr>
            <td data-th="#">2</td>
            <td data-th="First Name">Joe</td>
            <td data-th="Last Name">Sixpack</td>
            <td data-th="Language">English</td>
        </tr>
        <tr>
            <td data-th="#">3</td>
            <td data-th="First Name">Stu</td>
            <td data-th="Last Name">Dent</td>
            <td data-th="Language">Code</td>
        </tr>
    </tbody>
</table>
````

*/
table {
  width: 100%;
  max-width: 100%;
}
th,
td {
  padding: 0.625rem 0.53125rem;
  text-align: left;
}
th {
  padding-top: 0.625rem;
}
@media all and (max-width: 47.9375em) {
  table {
    display: block;
    width: 100%;
  }
  tbody,
  tr,
  th,
  td {
    border: 0;
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }
  thead {
    display: none;
    visibility: hidden;
  }
  tr {
    margin-top: 1.25em;
  }
  td[data-th]:before {
    content: attr(data-th) ":\00A0";
    display: inline-block;
    font-weight: bold;
  }
  td:empty {
    display: none;
  }
}
@media all and (min-width: 48em) {
  th,
  td {
    padding: 0.625rem 1.0625rem;
  }
}
/*

Panel

Used to provide messaging to the user, whether it be direct or through calls to 
action. The panel typically indicates a visual separation and implied 
sub-grouping of its content.

```panels
<div class="panel">
    <h3>This is a Panel</h3>
    <p>This is a <a href="#">feedback message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="panel">
    <p>This is a feedback panel with no heading.</p>
</div>
````

*/
.panel,
[class*="panel-"],
[class*="-panel"] {
  margin-top: 1.25em;
  padding: 1.25rem;
  background-color: #dadcde;
}
.panel > :first-child,
[class*="panel-"] > :first-child,
[class*="-panel"] > :first-child {
  margin-top: 0;
}
.panel h1,
[class*="panel-"] h1,
[class*="-panel"] h1,
.panel h2,
[class*="panel-"] h2,
[class*="-panel"] h2,
.panel h3,
[class*="panel-"] h3,
[class*="-panel"] h3,
.panel h4,
[class*="panel-"] h4,
[class*="-panel"] h4,
.panel h5,
[class*="panel-"] h5,
[class*="-panel"] h5,
.panel h6,
[class*="panel-"] h6,
[class*="-panel"] h6 {
  color: inherit;
}
[class*="panel--thin"] {
  padding: 0.625rem;
}
/*

Alert Panel

This variation of the standard `.panel` is used specifically for delivering 
direct messages to the user, whether they be informative, complimentary, 
or preventative.

````info-alert-panels
<div class="alert-panel--info">
    <h3>This is a Panel</h3>
    <p>This is an <a href="#">alert message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="alert-panel--info">
    <p>This is an alert panel with no heading.</p>
</div>
````

*/
/*

````error-alert-panels
<div class="alert-panel--error">
    <h3>This is a Panel</h3>
    <p>This is an <a href="#">alert message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="alert-panel--error">
    <p>This is an alert panel with no heading.</p>
</div>
````

*/
/*

````success-alert-panels
<div class="alert-panel--success">
    <h3>This is a Panel</h3>
    <p>This is an <a href="#">alert message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="alert-panel--success">
    <p>This is an alert panel with no heading.</p>
</div>
````

*/
[class*="alert-panel"] {
  color: #fff;
}
.alert-panel {
  background: #156d8e;
}
.alert-panel--error {
  background: #CC4A47;
}
.alert-panel--success {
  background: #72bc44;
}
/*

WYSIWYG

Though we keep our styles as flexible and modular as possible, there may be 
times, as when using a WYSIWYG editor, when some styles need to be baked in. 
Here, we specify a `.wysiwyg` class that wraps around that content, and 
hardcode some of those styles

    <div class="wysiwyg">
        [WYSIWYG Content]
    </div>

*/
.wysiwyg h1 {
  margin-top: 0;
}
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
  margin-top: .75em;
}
.wysiwyg p {
  text-align: justify;
}
.wysiwyg .img--right {
  float: right;
  margin-left: 1.875rem;
}
.wysiwyg .img--left {
  float: left;
  margin-right: 1.875rem;
}
.wysiwyg .img--left-fullWidth {
  margin-right: 1.875rem;
}
.wysiwyg .img--center {
  text-align: center;
}
.article__content--section .wysiwyg p:not(.article__content--sectionImage) {
  text-indent: 20px;
}
body {
  counter-reset: sectionHeading;
}
.articleTheme {
  position: relative;
}
@media all and (min-width: 62em) {
  .article__toc--mainWwrapper {
    display: flex;
  }
  .was-sticky .article__toc--mainWwrapper {
    align-items: stretch;
    justify-content: stretch;
    flex: 1 0 auto;
  }
}
@media all and (min-width: 62em) {
  .article__toc--wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    right: calc(((100% / 12) * 9) + 17px);
    width: calc((((100% / 12) * 3) - 17px) + (4.3478260869565215% - 17px));
    z-index: 1;
  }
  .article__toc--wrapper.is-sticky {
    position: fixed;
    top: 0;
    height: 100%;
    margin-left: 0;
    right: calc(((((92% + 34px) / 12) * 9)) + 4%);
  }
  .article__toc--wrapper.was-sticky {
    bottom: 0;
    top: auto;
  }
}
@media all and (min-width: 77.5em) {
  .article__toc--wrapper {
    width: calc((((100% / 12) * 3) - 17px) + (12.5% - 17px));
  }
  .article__toc--wrapper.is-sticky {
    left: auto;
    right: calc(((((80% + 34px) / 12) * 9)) + 10%);
  }
}
@media all and (min-width: 112.5em) {
  .article__toc--wrapper.is-sticky {
    right: calc((((1440px + 34px) / 12) * 9) + (50% - 720px));
  }
}
.article__toc--revealTarget {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: #29333a;
}
@media all and (max-width: 61.9375em) {
  .article__toc--revealTarget {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    -webkit-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    /* Positioning */
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    height: calc(100vh - 50px);
    /* Display & Box Model */
    overflow: auto;
    overflow-x: hidden;
    width: 80%;
  }
  .article__toc--revealTarget.is-revealed {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@media all and (min-width: 62em) {
  .article__toc--revealTarget {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .article__toc--revealTarget.focusable:active,
  .article__toc--revealTarget.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  .article__toc--revealTarget.is-revealed {
    width: auto;
    height: auto;
    margin: 0;
    position: relative;
    clip: auto;
    /* Pretty */
    overflow: visible;
  }
}
.back-to-top {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: #177da4;
  z-index: 10;
  text-align: right;
  padding: 0;
}
@media all and (min-width: 62em) {
  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    left: auto;
    height: auto;
    font-size: 14px;
    background: transparent;
    text-align: left;
    padding: 5px 20px;
  }
}
.back-to-top-cta {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857143;
  background-color: #177da4;
  color: #fff;
  display: inline-block;
  padding: 8px 10px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media all and (max-width: 61.9375em) {
  .back-to-top-cta {
    padding: 8px 20px;
    display: inline-flex;
    align-items: center;
    height: 50px;
    border-left: 1px solid #156d8e;
  }
}
@media all and (max-width: 61.9375em) {
  .back-to-top-cta:hover,
  .back-to-top-cta:focus {
    color: #fff;
  }
}
@media all and (min-width: 62em) {
  .back-to-top-cta:hover,
  .back-to-top-cta:focus {
    background-color: #115b77;
    color: #fff;
  }
}
.back-to-top-cta:after {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  color: #156d8e;
  line-height: 1;
  font-size: 1.5em;
  margin-left: .2em;
  vertical-align: middle;
  background: transparent url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 128 129' style='enable-background:new 0 0 128 129;' xml:space='preserve' fill='%23ffffff'%3E%3Cg id='Layer_3'%3E%3C/g%3E%3Cg id='Layer_2'%3E%3Cpath d='M86.5,64.6c0-0.1,0-0.4-0.1-0.5c0-0.8-0.4-1.7-1.1-2.4L66,42.3c-0.1,0-0.1,0-0.1-0.1l-13-13c-1.6-1.6-4.2-1.6-5.8,0 l-4.4,4.4c-1.5,1.5-1.5,4.2,0,5.8l25.2,25.2L42.8,89.7c-1.5,1.6-1.6,4.3,0,5.8l4.4,4.4c1.6,1.6,4.2,1.6,5.8,0l12.9-12.9 c0,0,0.1,0,0.1-0.1l19.4-19.4c0.7-0.7,1.1-1.6,1.1-2.4C86.5,65,86.5,64.7,86.5,64.6L86.5,64.6L86.5,64.6L86.5,64.6z'/%3E%3C/g%3E%3C/svg%3E") 0 0 no-repeat;
  transform: rotate(-90deg);
}
.article__toc--collapse {
  position: fixed;
  bottom: 8px;
  left: 0;
  display: block;
}
@media all and (max-width: 47.9375em) {
  .article__toc--collapse {
    bottom: 0;
    left: 0;
  }
}
@media all and (max-width: 61.9375em) {
  .article__toc--collapse {
    z-index: 11;
  }
}
@media all and (min-width: 62em) {
  .article__toc--collapse {
    position: relative;
    bottom: auto;
    right: auto;
    text-align: center;
    left: auto;
  }
}
.article__toc--collapseLink {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857143;
  background-color: #177da4;
  color: #fff;
  display: block;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.25s ease;
}
@media all and (max-width: 47.9375em) {
  .article__toc--collapseLink {
    height: 50px;
    display: flex;
    align-items: center;
  }
}
@media all and (min-width: 62em) {
  .article__toc--collapseLink {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.59090909;
    padding: 18px 10px;
  }
}
@media all and (max-width: 61.9375em) {
  .article__toc--collapseLink:hover,
  .article__toc--collapseLink:focus {
    color: #fff;
  }
}
@media all and (min-width: 62em) {
  .article__toc--collapseLink:hover,
  .article__toc--collapseLink:focus {
    background-color: #637481;
    color: #fff;
  }
}
.article__toc--collapseLink:after {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: #156d8e;
  line-height: 1;
  font-size: 1.5em;
  vertical-align: bottom;
  transition: transform .25s ease;
}
.article__toc--collapseLink.was-revealed:after {
  content: "";
  background: transparent url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 128 129' style='enable-background:new 0 0 128 129;' xml:space='preserve' fill='%23ffffff'%3E%3Cg id='Layer_3'%3E%3C/g%3E%3Cg id='Layer_2'%3E%3Cpath d='M86.5,64.6c0-0.1,0-0.4-0.1-0.5c0-0.8-0.4-1.7-1.1-2.4L66,42.3c-0.1,0-0.1,0-0.1-0.1l-13-13c-1.6-1.6-4.2-1.6-5.8,0 l-4.4,4.4c-1.5,1.5-1.5,4.2,0,5.8l25.2,25.2L42.8,89.7c-1.5,1.6-1.6,4.3,0,5.8l4.4,4.4c1.6,1.6,4.2,1.6,5.8,0l12.9-12.9 c0,0,0.1,0,0.1-0.1l19.4-19.4c0.7-0.7,1.1-1.6,1.1-2.4C86.5,65,86.5,64.7,86.5,64.6L86.5,64.6L86.5,64.6L86.5,64.6z'/%3E%3C/g%3E%3C/svg%3E") 0 0 no-repeat;
  transform: rotate(0deg);
}
.article__toc--collapseLink.is-revealed:after {
  content: "";
  transform: rotate(180deg);
}
@media all and (min-width: 62em) {
  .article {
    margin-left: 25%;
  }
}
@media all and (min-width: 62em) {
  .is-sticky .article {
    margin-left: 25%;
  }
}
@media all and (min-width: 62em) {
  .was-sticky .article {
    margin-left: 0;
  }
}
.article-row {
  position: relative;
}
.was-sticky .article-row {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}
.article__currentSection {
  background-color: #edeae3;
  padding: 10px 20px;
  position: fixed;
  bottom: 20px;
  left: 20px;
}
@media all and (min-width: 62em) {
  .article__currentSection {
    display: block;
    position: relative;
    bottom: auto;
    left: auto;
  }
}
.article__currentSection--heading {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.66666667;
  line-height: 1.35em;
  margin-top: .25em;
}
.article__currentSection--subheading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 900;
  color: #156d8e;
  font-size: 1em;
  margin-top: 0;
  text-transform: uppercase;
}
.article__toc {
  padding: 10px 0;
  flex: 1 1 auto;
}
@media all and (min-width: 62em) {
  .article__toc {
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 20px;
  }
}
.article__toc--heading {
  margin: 0 20px;
  border-bottom: 1px solid #edeae3;
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 900;
  color: #156d8e;
  font-size: 1em;
  margin-top: 0;
  text-transform: uppercase;
}
.article__toc--list {
  margin: 20px 0 0 0;
  padding: 0;
  list-style: none;
}
.article__toc--listItem.is-current {
  background-color: #8c9398;
}
.article__toc--itemLink {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625;
  color: #fff;
  display: block;
  padding: 10px 20px 10px 55px;
  background-color: transparent;
  position: relative;
  text-decoration: none;
  border-left: 0 solid #156d8e;
  transition: background-color 0.25s ease, color 0.25s ease, border-left 0.25s ease, padding-left 0.25s ease;
}
@media all and (min-width: 77.5em) {
  .article__toc--itemLink {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.66666667;
  }
}
.article__toc--itemLink:hover,
.article__toc--itemLink:focus,
.article__toc--itemLink.is-current {
  background-color: #fff;
  color: #156d8e;
}
.article__toc--itemLink:hover .article__toc--itemNumber,
.article__toc--itemLink:focus .article__toc--itemNumber,
.article__toc--itemLink.is-current .article__toc--itemNumber {
  background-color: #156d8e;
  color: #fff;
}
.article__toc--itemNumber {
  background-color: transparent;
  display: block;
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625;
  color: #177da4;
  line-height: 3;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50px;
  padding-right: 5px;
  text-align: right;
  transition: color 0.25s ease, background-color 0.25s ease;
}
.article__author {
  background-color: #29333a;
  flex: 0 0 auto;
}
.article__author--reveal {
  background-color: #586773;
  color: #b4b9bc;
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  transition: background-color 0.25s ease, color 0.25s ease;
}
.article__author--reveal:after {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: #156d8e;
  line-height: 1;
  font-size: 1.5em;
  vertical-align: bottom;
  transform: translate3d(0, 0, 0) rotate(0deg);
  transition: transform .25s ease;
}
.article__author--reveal.was-revealed:after {
  content: "";
  background: transparent url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 128 129' style='enable-background:new 0 0 128 129;' xml:space='preserve' fill='%23ffffff'%3E%3Cg id='Layer_3'%3E%3C/g%3E%3Cg id='Layer_2'%3E%3Cpath d='M86.5,64.6c0-0.1,0-0.4-0.1-0.5c0-0.8-0.4-1.7-1.1-2.4L66,42.3c-0.1,0-0.1,0-0.1-0.1l-13-13c-1.6-1.6-4.2-1.6-5.8,0 l-4.4,4.4c-1.5,1.5-1.5,4.2,0,5.8l25.2,25.2L42.8,89.7c-1.5,1.6-1.6,4.3,0,5.8l4.4,4.4c1.6,1.6,4.2,1.6,5.8,0l12.9-12.9 c0,0,0.1,0,0.1-0.1l19.4-19.4c0.7-0.7,1.1-1.6,1.1-2.4C86.5,65,86.5,64.7,86.5,64.6L86.5,64.6L86.5,64.6L86.5,64.6z'/%3E%3C/g%3E%3C/svg%3E") 0 0 no-repeat;
  transform: rotate(0deg);
  transition: transform .25s ease;
}
.article__author--reveal.is-revealed:after {
  content: "";
  transform: rotate(90deg);
}
.article__author--reveal:hover,
.article__author--reveal:focus {
  background-color: #4d5a65;
  color: #8c9398;
}
.article__author--heading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 900;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5625;
  color: #b4b9bc;
  text-transform: uppercase;
}
.article__author--title {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.66666667;
  color: #b4b9bc;
  font-style: italic;
  margin: 0;
  padding: 0 20px;
}
.article__author--name {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.66666667;
  color: #fff;
  margin: 0;
  padding: 10px 20px 5px;
}
.article__author--cta {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857143;
  display: block;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.article__header {
  padding-top: 40px;
}
@media all and (min-width: 48em) {
  .article__header {
    padding-top: 60px;
  }
}
@media all and (min-width: 62em) {
  .article__header {
    padding-top: 80px;
  }
}
.article__header--meta {
  display: flex;
  margin-top: 1.25em;
}
.article__header--metaIssue {
  background-color: #177da4;
  color: #fff;
  padding: 5px 10px;
}
.article__header--metaIssueDate {
  background-color: #edeae3;
  padding: 5px 10px;
}
.article__header--heading {
  color: #29333a;
}
.article__header--subheading,
.search__subheader--subheading,
.author__card--about {
  font-size: 28px;
  font-size: 1.75rem;
  line-height: 1.42857143;
  margin-top: 20px;
}
.article__header--issueInfo {
  display: flex;
  border-bottom: 1px solid #29333a;
  margin-top: 20px;
  padding-bottom: 20px;
}
.article__header--issueInfo p {
  margin-top: 0;
}
.article__header--iconList {
  margin: 20px 0 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.article__header--iconListItem {
  font-size: 1.75em;
  padding-right: 5px;
}
.article__header--iconLink {
  display: block;
  line-height: 1;
  padding: 4px;
  text-decoration: none;
}
.article__header--iconLink > svg {
  color: #177da4;
}
.article__header--iconLink > span {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 900;
  font-size: .575em;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 10px;
}
.article__header--iconLink:hover > svg,
.article__header--iconLink:focus > svg {
  color: #156d8e;
}
.article__content {
  margin-top: 20px;
  counter-reset: footnotes;
}
@media all and (min-width: 62em) {
  .article__content {
    margin-top: 40px;
  }
}
@media all and (min-width: 62em) {
  .article__content--sectionNavWrapper.is-sticky {
    position: fixed;
    top: 0;
  }
}
.article__content--sectionNav {
  background-color: #8c9398;
}
.article__content--sectionHeading {
  margin-top: 0;
  display: flex;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
}
.article__content--sectionHeading:before {
  counter-increment: sectionHeading 1;
  content: counter(sectionHeading, upper-roman) ".\0000a0";
  color: #29333a;
  flex: 0 0 auto;
}
span.article__content--sectionHeading {
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
}
span.article__content--sectionHeading.focusable:active,
span.article__content--sectionHeading.focusable:focus {
  width: auto;
  height: auto;
  margin: 0;
  clip: auto;
  overflow: visible;
  position: static;
}
.article__content--sectionSubheading {
  margin-top: .75em;
}
.article__content--navLink {
  display: block;
  padding: 10px 20px;
}
.article__content--authorCTAImage {
  width: 100%;
}
@media all and (min-width: 62em) {
  .fnoteWrap.is-active:before {
    opacity: 1;
  }
}
.fnoteLink:before {
  content: counter(footnotes);
  counter-increment: footnotes;
  text-indent: 0;
  display: inline-block;
  vertical-align: super;
  line-height: 1;
  font-size: .75em;
  font-weight: 700;
  background-color: transparent;
  color: #177da4;
  padding: 3px 4px 2px;
}
@media all and (max-width: 61.9375em) {
  .fnoteLink:before {
    counter-increment: footnotes;
  }
}
.footSpan {
  text-indent: 0;
  display: none;
}
@media all and (max-width: 61.9375em) {
  .footSpanContent {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .footSpanContent.focusable:active,
  .footSpanContent.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  body:hover .footSpanContent a,
  body:hover .footSpanContent input,
  body:hover .footSpanContent button {
    display: none;
  }
}
@media all and (min-width: 62em) {
  .footSpanContent {
    display: inline-block;
    text-indent: 0;
    font-family: Arial, Arial Unicode, Lucida Sans Unicode, sans-serif;
    font-weight: normal;
    background-color: transparent;
    color: #29333a;
    font-size: .875em;
    padding: 10px 12px;
    position: absolute;
    left: calc(75% + 17px);
    width: calc(25% - 34px);
    text-align: left;
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    opacity: 0;
    transition: opacity .25s ease;
  }
  .footSpanContent:before {
    content: counter(footnotes);
    display: inline-block;
    text-indent: 0;
    position: absolute;
    left: -8px;
    margin-top: -16px;
    z-index: 1;
    vertical-align: super;
    line-height: 1;
    font-size: .75em;
    font-weight: 700;
    background-color: transparent;
    color: #177da4;
    padding: 3px 4px 2px;
    opacity: 0;
    transition: opacity .25s ease;
  }
  .footSpanContent.is-active {
    opacity: 1;
  }
  .footSpanContent.is-active:before {
    opacity: 1;
  }
}
.footSpanContent .closeButton {
  display: none;
}
@media all and (min-width: 62em) {
  .footSpanContent .closeButton {
    display: block;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 100%;
    border: 0;
    background: transparent;
    color: #992D2B;
  }
}
.footnotes {
  border-top: 2px solid #29333a;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media all and (min-width: 62em) {
  .footnotes {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.footnotes__header {
  margin-top: 0;
}
.footnotes__wrapper {
  margin-top: 20px;
}
.footnotes__item {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  position: relative;
  margin-bottom: 20px;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.footontes__number {
  font-size: 9px;
  font-size: 0.5625rem;
  line-height: 1.11111111;
  background-color: #992D2B;
  color: #fff;
  padding: 3px 4px 2px;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
  min-width: 23px;
  text-align: center;
}
.footnotes__content {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.66666667;
  display: block;
  padding-left: 30px;
  padding-right: 0;
}
@media all and (min-width: 62em) {
  .footnotes__content {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.42857143;
  }
}
.footnoteWrapper {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate3d(-50%, 100%, 0);
  width: calc(100% - 20px);
  transition: transform .4s ease, opacity .4s ease;
  opacity: 0;
  z-index: 12;
}
.footnoteWrapper.is-active {
  transform: translate3d(-50%, 0, 0);
  opacity: 1;
}
@media all and (min-width: 62em) {
}
.active-footnote-close {
  background-color: #992D2B;
}
.active-footnote-close .icon-close {
  fill: #fff;
  transform: rotate(45deg);
}
.footnoteWrapper--content {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  background-color: #d8d8d8;
  color: #29333a;
  font-size: .875em;
  padding: 10px 12px;
}
.article__content--section {
  border-bottom: 1px solid #666d72;
  padding-bottom: 40px;
  margin-top: .75em;
}
.article__content--section:last-child {
  border-bottom: none;
}
@media all and (min-width: 48em) {
  .article__content--section {
    padding-bottom: 60px;
  }
}
@media all and (min-width: 62em) {
  .article__content--section {
    padding-bottom: 80px;
  }
}
.article__content--section:focus {
  outline: none;
}
@media all and (min-width: 62em) {
  .article__content--sectionInlineImage {
    display: flex;
  }
}
@media all and (min-width: 62em) {
  .article__content--sectionInlineImage figure {
    padding-right: 5%;
    width: 70%;
  }
}
.article__content--sectionInlineImage p.caption {
  text-indent: 0;
}
@media all and (min-width: 62em) {
  .article__content--sectionInlineImage p.caption {
    width: 25%;
    display: flex;
    align-items: center;
  }
}
.issueLanding,
.search {
  background-color: #fff;
}
@media all and (min-width: 48em) {
}
.issue__header + .issueLanding,
.issue__header + .search {
  margin-top: 0;
  padding-top: 0;
}
.issue__header + .issueLanding .issueLanding__articles:first-child,
.issue__header + .search .issueLanding__articles:first-child,
.issue__header + .issueLanding .search__articles:first-child {
  margin-top: 0;
}
.issue__header + .issueLanding .issueLanding__content,
.issue__header + .search .issueLanding__content,
.issue__header + .issueLanding .search__content {
  position: relative;
  z-index: 2;
  padding-top: 3em;
}
@media all and (min-width: 77.5em) {
  .issue__header + .issueLanding .issueLanding__content,
  .issue__header + .search .issueLanding__content,
  .issue__header + .issueLanding .search__content {
    margin-top: 0;
    padding-top: 4em;
  }
}
.issue__header + .issueLanding .issueLanding__subheader,
.issue__header + .search .issueLanding__subheader,
.issue__header + .issueLanding .search__subheader {
  background: none;
  padding: 0;
}
.issueLanding__subheader,
.search__subheader {
  text-align: center;
  padding: 40px 0;
  background: #156d8e;
  position: relative;
}
@media all and (min-width: 62em) {
  .issueLanding__subheader,
  .search__subheader {
    padding: 60px 0;
  }
}
.issueLanding__subheader--heading,
.search__subheader--heading {
  margin-top: 0;
  text-transform: uppercase;
}
.issueLanding__subheader--issueMeta {
  position: relative;
  z-index: 1;
}
.issueLanding__subheader--issueMeta h1 {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  color: #fff;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}
@media all and (min-width: 62em) {
  .issueLanding__subheader--issueMeta h1 {
    font-size: 3em;
  }
}
.has-image .issueLanding__subheader--issueMeta h1 {
  text-shadow: 2px 2px 13px rgba(0, 0, 0, 0.8);
}
.issueLanding__subheader--issueNumbers {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  color: #fff;
  display: inline-block;
  font-size: .75em;
  padding: 5px 20px;
}
.issueLanding__subheader--issueDate {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  background-color: #edeae3;
  color: #29333a;
  display: inline-block;
  padding: 5px 20px;
}
.issueLanding__subheader--subheading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 1.25rem;
  margin-top: 1.5em;
}
.issueLanding__CTAs {
  display: flex;
}
.issueLanding__article--wrapper,
.search__articles--wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 20px;
}
.issueLanding__content > .wrapper > div,
.issueLanding__content > .gridLines-wrapper > div,
.search__content > .wrapper > div {
  margin-top: 40px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 4%;
  padding-right: 4%;
}
@media all and (min-width: 48em) {
  .issueLanding__content > .wrapper > div,
  .issueLanding__content > .gridLines-wrapper > div,
  .search__content > .wrapper > div {
    margin-top: 2em;
  }
}
@media all and (min-width: 62em) {
  .issueLanding__content > .wrapper > div,
  .issueLanding__content > .gridLines-wrapper > div,
  .search__content > .wrapper > div {
    padding-left: 0;
    padding-right: 0;
    margin-top: 3em;
  }
}
@media all and (min-width: 77.5em) {
  .issueLanding__content > .wrapper > div,
  .issueLanding__content > .gridLines-wrapper > div,
  .search__content > .wrapper > div {
    margin-top: 4em;
  }
}
.issueLanding__content > .wrapper > div:last-child,
.issueLanding__content > .gridLines-wrapper > div:last-child,
.search__content > .wrapper > div:last-child {
  margin-left: 0;
  margin-right: 0;
}
.issueLanding__content--sectionHeading,
.search__content--sectionHeading {
  color: #177da4;
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}
@media all and (min-width: 62em) {
  .issueLanding__content--sectionHeading,
  .search__content--sectionHeading {
    font-size: 3em;
  }
}
.issueLanding__specialReport .issueLanding__content--sectionHeading,
.issueLanding__specialReport .search__content--sectionHeading {
  color: #CCA14B;
  background-color: #29333a;
  display: block;
  padding: 10px 4% 20px;
  margin-top: -40px;
  margin-bottom: 0;
  text-align: left;
}
@media all and (min-width: 62em) {
  .issueLanding__specialReport .issueLanding__content--sectionHeading,
  .issueLanding__specialReport .search__content--sectionHeading {
    width: 50%;
    margin-top: -90px;
    padding: 20px calc((100% / 12) - 34px);
  }
}
.issueLanding__specialReport .issueLanding__specialReport--content {
  padding-left: 4%;
  padding-right: 4%;
}
@media all and (min-width: 62em) {
  .issueLanding__specialReport .issueLanding__specialReport--content {
    width: 50%;
    padding-left: calc((100% / 12) - 34px);
    padding-right: calc((100% / 12) - 34px);
  }
}
.issueLanding__content--sectionsubHeading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.66666667;
  color: #29333a;
  margin-top: 0;
  margin-bottom: 20px;
}
.issueLanding__resources--heading {
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
}
.issueLanding__resources--row {
  margin-top: 1.25em;
}
.issueLanding__specialReport {
  background-color: #29333a;
  color: #fff;
  margin-top: 80px;
  padding-top: 0;
  padding-bottom: 80px;
}
@media all and (min-width: 62em) {
  .issueLanding__specialReport {
    padding-top: 0;
    padding-bottom: 70px;
    margin-top: 120px;
  }
}
.archive__message {
  margin-top: 0;
}
.issueLanding__community {
  background-color: #fff;
  padding-top: 30px;
  padding-bottom: 70px;
}
@media all and (min-width: 62em) {
  .issueLanding__community {
    padding-top: 60px;
  }
}
.issueLanding__specialReport + .issueLanding__community {
  margin-top: 0;
}
.issueLanding__community--heading {
  color: #29333a;
  margin: 0;
}
.issueLanding__community--cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.0625rem;
  margin-top: 20px;
}
@media all and (min-width: 48em) {
  .issueLanding__community--cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media all and (min-width: 62em) {
  .issueLanding__community--cards {
    margin-top: 0;
  }
}
@media all and (min-width: 77.5em) {
  .issueLanding__community--cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.main-header {
  background-color: #f5f2ec;
  position: relative;
  z-index: 1;
}
@media all and (min-width: 48em) {
  .main-header {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  }
}
@media all and (min-width: 48em) {
  .header__wrapper {
    display: flex;
  }
}
.header__logo {
  max-width: 200px;
  padding: 9px 20px 10px 0;
}
@media all and (min-width: 48em) {
  .header__logo {
    flex: 0 0 auto;
    width: 22%;
    max-width: 250px;
    padding-top: 0.78125rem;
  }
}
@media all and (min-width: 62em) {
  .header__logo {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
.logo {
  display: block;
}
@media all and (min-width: 48em) {
}
.logo img {
  display: block;
  margin: 0 auto;
}
@media all and (min-width: 62em) {
  .logo img {
    margin: 0;
  }
}
.header__search {
  text-align: center;
}
@media all and (min-width: 48em) {
  .header__search {
    text-align: right;
    margin-right: 80px;
    position: absolute !important;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f5f2ec;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
    padding: 0;
  }
}
@media all and (min-width: 77.5em) {
  .header__search {
    right: 8%;
  }
}
@media all and (min-width: 112.5em) {
  .header__search {
    right: calc((100vw - 1440px) / 2);
  }
}
.search__form {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  display: inline-block;
}
@media all and (min-width: 48em) {
  .search__form {
    padding: 0.625rem 0.53125rem;
  }
}
@media all and (min-width: 62em) {
}
.search__form--wrapper {
  display: flex;
  justify-content: center;
}
@media all and (min-width: 62em) {
  .search__form--wrapper {
    justify-content: flex-end;
  }
}
.search__form--label {
  border: 3px solid #586773;
}
.search__form--input {
  border-radius: 0;
  border: 0;
}
.search__form--submit {
  border: 0;
  box-shadow: none;
  background: white;
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  background-color: #fff;
  border: 3px solid #177da4;
  color: #156d8e;
  border-radius: 0;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-left: 1.0625rem;
}
.search__form--submit:hover,
.search__form--submit:focus,
.search__form--submit.is-current {
  background-color: #f2f2f2;
  border-color: #115b77;
  color: #0e4b62;
}
.issue__header {
  background: #fff;
}
.issue__header .issue__header--wrapper {
  position: relative;
  overflow: hidden;
  padding: 40px 0 125px;
}
@media all and (min-width: 48em) {
  .issue__header .issue__header--wrapper {
    padding-bottom: 20vw;
  }
}
@media all and (min-width: 62em) {
  .issue__header .issue__header--wrapper {
    padding-bottom: 25vw;
    max-height: 500px;
  }
}
@media all and (min-width: 77.5em) {
  .issue__header .issue__header--wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1440px;
    padding-bottom: 20vw;
    max-height: 50vh;
  }
}
.issue__header .issueLanding__subheader--issueMeta {
  margin-top: 1.25em;
}
.issue__header--figure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  margin-top: 0;
}
.issueLanding__subheader .issue__header--figure,
.search__subheader .issue__header--figure {
  opacity: .5;
}
.issue__header--content {
  position: relative;
  z-index: 1;
}
.issue__header--heading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 900;
  color: #fff;
  margin-top: 0.3125rem;
  text-shadow: 2px 2px 13px rgba(0, 0, 0, 0.8);
}
.issue__header--issueMeta {
  margin-top: 0.625rem;
}
.issue__header--issueNumbers {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.59090909;
  color: #fff;
  padding: 0;
  margin-top: 0;
  text-shadow: 2px 2px 13px rgba(0, 0, 0, 0.8);
}
.issue__header--desc {
  color: #fff;
  font-size: 1.25rem;
  margin-top: 0;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
}
@media all and (min-width: 48em) {
  .header__links {
    padding-top: 0.625rem;
    padding-bottom: 0.3125rem;
    flex: 0 0 auto;
    width: 72%;
  }
}
@media all and (min-width: 62em) {
  .header__links {
    padding-top: 1.875rem;
    padding-bottom: 0;
  }
}
.header__links--link {
  color: #177da4;
  text-decoration: none !important;
  font-weight: bold;
}
@media all and (min-width: 48em) {
  .header__links--link {
    padding: 0 0.53125rem;
  }
}
@media all and (min-width: 62em) {
  .header__links--link {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.66666667;
  }
}
.main-footer {
  background-color: #f5f2ec;
  color: #177da4;
  position: relative;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}
.main-footer .logo {
  padding-left: 0;
}
.main-footer .logo img {
  margin: 0;
}
.footer__wrapper {
  padding: 0 0 40px;
}
@media all and (min-width: 48em) {
  .footer__wrapper {
    padding: 60px 0 40px;
  }
}
@media all and (min-width: 62em) {
  .footer__wrapper {
    padding: 100px 0;
  }
}
.footer__logo {
  margin-top: 1.25em;
}
@media all and (min-width: 62em) {
  .footer__logo {
    margin-top: 0;
  }
}
.footer__cail {
  color: #177da4;
  font-weight: bold;
}
.footer__address {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857143;
}
@media all and (min-width: 62em) {
  .footer__address {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5625;
  }
}
.footer__social--list {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
  display: flex;
}
.footer__social--link {
  border-radius: 50%;
  color: #fff;
  display: block;
  padding: 4px;
  margin-right: 1.25rem;
}
@media all and (min-width: 62em) {
  .footer__social--link {
    padding: 6px;
  }
}
.footer__social--link svg {
  width: 1.75em;
  height: 1.75em;
  vertical-align: middle;
}
@media all and (min-width: 62em) {
  .footer__social--link svg {
    width: 2em;
    height: 2em;
  }
}
.footer__social--link.linkedin {
  background-color: #0E76A8;
}
.footer__social--link.twitter {
  background-color: #55ACEE;
}
.footer__social--link.facebook {
  background-color: #3B5998;
}
.footer__links--heading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.59090909;
  color: #29333a;
  text-transform: uppercase;
  padding-bottom: .75em;
  line-height: 1;
  border-bottom: 1px solid #29333a;
}
.footer__links--list {
  padding: 1em 0 0;
  margin: 0;
  list-style: none;
}
.footer__links--link {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857143;
  color: #177da4;
  text-transform: uppercase;
  padding-left: 1em;
  text-decoration: none;
  line-height: 1.75;
  display: block;
}
@media all and (min-width: 62em) {
  .footer__links--link {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5625;
  }
}
.copyright {
  padding-bottom: 1.25em;
}
a[href^="tel:"] {
  color: #177da4;
  text-decoration: none;
}
.footer__links--legal {
  color: #177da4;
  text-decoration: none;
}
.footer__links--legal:focus,
.footer__links--legal:hover {
  color: #fff;
  text-decoration: underline;
}
.search {
  padding-top: 0;
}
.search__subheader--heading {
  color: #fff;
  text-transform: none;
}
.search__subheader--subheading {
  color: #fff;
}
.search__content--sectionHeading.no-results {
  margin-bottom: 1.25em;
}
.search__content--sectionHeading span {
  color: #177da4;
}
.articleCard {
  display: block;
  margin-bottom: 20px;
  position: relative;
}
@media all and (max-width: 47.9375em) {
  .articleCard {
    width: 100%;
  }
}
@media all and (min-width: 48em) {
  .articleCard {
    margin-bottom: 40px;
    transition: box-shadow 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  .articleCard:nth-child(odd) {
    margin-right: 18px;
  }
  .articleCard:nth-child(even) {
    margin-left: 18px;
  }
  .articleCard:hover,
  .articleCard:focus {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.55);
  }
  .issueLanding__article--wrapper .articleCard,
  .search__articles--wrapper .articleCard {
    flex: 0 0 auto;
    width: calc(50% - 18px);
    display: flex;
  }
  .search__articles--wrapper .articleCard {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
}
.articleCard__category {
  flex: 0 0 auto;
  width: 50px;
}
@media all and (min-width: 62em) {
  .articleCard__category {
    width: 55px;
  }
}
.articleCard__category > .icon {
  width: 2em;
  height: 2em;
  fill: #177da4;
}
.articleCard__content {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.articleCard__content img {
  width: 100%;
  max-width: max-content;
  height: auto;
  flex-shrink: 0;
}
.articleCard__content--link {
  display: flex;
  flex: 0 0 auto;
  border: 1px solid #177da4;
  width: 100%;
  text-decoration: none;
  padding: 20px;
  background-color: #edeae3;
  transition: background-color 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media all and (min-width: 62em) {
  .articleCard__content--link {
    padding: 30px 40px 30px 30px;
  }
}
@media all and (min-width: 77.5em) {
  .articleCard__content--link {
    padding: 35px 80px 40px 35px;
  }
}
.articleCard:hover .articleCard__content--link,
.articleCard:focus .articleCard__content--link {
  background-color: #fff;
}
.articleCard__heading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  margin-top: 0;
}
.articleCard__desc {
  color: #29333a;
  font-weight: normal;
}
.articleCard__img--wrapper {
  flex: 0 0 auto;
}
.articleCard__content--authorInfo {
  display: flex;
  color: #29333a;
  font-weight: normal;
}
.articleCard__content--authorInfo p {
  padding-right: 20px;
  font-style: italic;
  font-weight: bold;
  margin-top: .5em;
}
.articleCard__content--tags {
  display: flex;
  color: #29333a;
  font-weight: normal;
}
.additional__links {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  background-color: #fff;
  border: 3px solid #177da4;
  color: #156d8e;
  border-radius: 0;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  z-index: 2;
}
.additional__links:hover,
.additional__links:focus,
.additional__links.is-current {
  background-color: #f2f2f2;
  border-color: #115b77;
  color: #0e4b62;
}
.articleCard__content--ctaWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0 ;
  right: 0;
  z-index: 1;
}
.articleCard__content--cta {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.42857143;
  display: block;
  line-height: 1;
  text-transform: uppercase;
}
@media all and (max-width: 47.9375em) {
  .articleCard__content--cta {
    margin-top: 0;
  }
}
@media all and (min-width: 48em) {
  .articleCard__content--cta {
    margin-top: auto;
  }
}
.articleCard__content--bylinelink {
  color: black !important;
  text-decoration: none !important;
}
.author__card--wrapper {
  background-color: #5893A1;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
}
@media all and (min-width: 62em) {
  .author__card--wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
#authorSection:focus {
  outline: none;
}
.author__card {
  margin-top: 30px;
}
@media all and (min-width: 62em) {
  .author__card {
    padding-left: calc(((100% + 34px) / 12) * 3);
  }
}
.author__card--image {
  width: 100%;
}
.author__card--about {
  color: #fff;
  margin-top: 0;
}
@media all and (min-width: 62em) {
  .author__card--about {
    padding-left: calc(((100% + 34px) / 12) * 3);
  }
}
@media all and (max-width: 47.9375em) {
  .author__card--desc {
    margin-top: 30px;
  }
}
.author__card--name {
  font-family: "Lora", "Times New Roman", Times, Georgia, serif;
  font-weight: 700;
  color: #fff;
  font-size: 1.375rem;
  letter-spacing: 1px;
  margin-top: .25em;
}
.author__card--title {
  color: #29333a;
  margin-top: .25em;
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-style: italic;
}
.tag__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
@media all and (min-width: 48em) {
  .tag__list {
    display: flex;
    flex-wrap: wrap;
  }
}
.tag__listItem {
  margin-top: 15px;
  margin-bottom: 16px;
  padding-right: 20px;
}
@media all and (min-width: 48em) {
  .tag__listItem {
    margin-bottom: 0;
  }
}
.tag__link {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  background-color: #29333a;
  color: #fff;
  display: inline-block;
  border-radius: 14px;
  padding: 3px 20px 2px;
  text-decoration: none;
  transition: background-color 0.25s ease;
}
.resourceCard {
  text-align: center;
}
.resourceCard__image {
  width: 100%;
}
.resourceCard__link {
  display: block;
  text-decoration: none;
}
.resourceCard__link:hover,
.resourceCard__link:focus {
  background-color: #e6e6e6;
}
.communityCard {
  border: 1px solid #8c9398;
  flex: 0 0 100%;
  margin-bottom: 20px;
  -webkit-box-shadow: 1px 1px 22px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 22px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 22px 0px rgba(0, 0, 0, 0.1);
}
.communityCard:only-child {
  border-width: 1px;
}
@media all and (min-width: 48em) {
  .communityCard {
    margin-bottom: 0;
  }
}
.communityCard__header {
  background-color: #177da4;
  text-align: center;
  padding: 30px 0;
}
.communityCard:nth-child(4n + 2) .communityCard__header {
  background-color: #992D2B;
}
.communityCard:nth-child(4n + 3) .communityCard__header {
  background-color: #405E26;
}
.communityCard:nth-child(4n + 4) .communityCard__header {
  background-color: #5F3699;
}
.communityCard__header--heading {
  font-size: 24px;
  font-size: 1.5rem;
  line-height: 1.66666667;
  color: #29333a;
  margin-top: .75em;
  color: #fff;
  margin: 0;
}
@media all and (min-width: 62em) {
  .communityCard__header--heading {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.42857143;
  }
}
@media all and (min-width: 62em) {
  .communityCard__header--heading {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 1.66666667;
  }
}
.communityCard__header--subheading {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #fff;
  line-height: 1.15;
  margin: 0;
  text-transform: uppercase;
}
.communityCard__date {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.66666667;
  background-color: #f5f2ec;
  padding: 10px 4px;
  text-align: center;
}
.communityCard__desc {
  background-color: #fff;
  padding: 20px;
}
.communityCard__desc--ctaWrapper {
  margin: .75em 0;
}
.communityCard__desc--cta {
  display: block;
}
.nav-bar__searchReveal {
  position: absolute;
  top: 1.25em;
  right: 60px;
  background-color: transparent;
  border: 0;
}
@media all and (min-width: 48em) {
  .nav-bar__searchReveal {
    top: 1em;
    right: 4%;
  }
}
@media all and (min-width: 62em) {
  .nav-bar__searchReveal {
    top: 2.1875rem;
  }
}
@media all and (min-width: 77.5em) {
  .nav-bar__searchReveal {
    right: 10%;
  }
}
@media all and (min-width: 112.5em) {
  .nav-bar__searchReveal {
    right: calc((100vw - 1440px) / 2);
  }
}
.nav-bar__searchReveal .icon {
  background-color: transparent;
  color: #156d8e;
  width: 1.25em;
  height: 1.25em;
}
.nav-bar__searchReveal .icon.delete {
  display: none;
}
.nav-bar__searchReveal.is-expanded .search {
  display: none;
}
.nav-bar__searchReveal.is-expanded .delete {
  display: block;
}
.nav-bar__title {
  margin: 0;
  padding: 9px 0;
  display: inline-block;
  color: #177da4;
  cursor: pointer;
  width: 40px;
  height: 41px;
  position: absolute;
  top: 0.625rem;
  right: 4%;
}
@media all and (min-width: 48em) {
  .nav-bar__title {
    top: 1.25rem;
  }
}
.nav-bar__title span {
  width: 30px;
  height: 3px;
  background-color: #177da4;
  display: block;
  position: relative;
  text-align: left;
  margin: 10px auto;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-duration: 75ms;
}
.nav-bar__title span:before {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #177da4;
  display: inline-block;
  position: absolute;
  top: -10px;
}
.nav-bar__title span:after {
  content: "";
  width: 30px;
  height: 3px;
  background-color: #177da4;
  display: inline-block;
  position: absolute;
  bottom: -10px;
}
.nav-bar__title.is-expanded span {
  transition-delay: .12s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: rotate(45deg);
}
.nav-bar__title.is-expanded span:before {
  top: 0;
  transition: top 75ms cubic-bezier(0.645, 0.045, 0.355, 1), opacity 75ms cubic-bezier(0.645, 0.045, 0.355, 1) 0.12s;
  opacity: 0;
}
.nav-bar__title.is-expanded span:after {
  bottom: 0;
  transition: bottom 75ms cubic-bezier(0.645, 0.045, 0.355, 1), transform 75ms cubic-bezier(0.645, 0.045, 0.355, 1) 0.12s;
  transform: rotate(-90deg);
}
@media all and (max-width: 47.9375em) {
  .nav-bar__list.is-expanded {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
}
.nav-bar__list ul {
  margin-top: 0;
  margin-bottom: 0.3125rem;
}
@media all and (min-width: 48em) {
  .nav-bar__list ul {
    text-align: right;
  }
}
.nav-bar__list ul li:first-child a {
  padding-left: 0;
}
.nav-bar__list ul li:last-child a {
  margin-top: 0;
}
@media all and (min-width: 48em) {
  .js .nav-bar--menu .nav-bar__title {
    display: none;
  }
  .js .nav-bar--menu [class*="nav-bar__list"] {
    max-height: 999px;
  }
  [class*="nav-bar__list"] > li,
  [class*="nav-bar__list"] [class*="list--x"] > li {
    width: auto;
  }
  [class*="nav-bar__list"] > li:nth-child( odd ),
  [class*="nav-bar__list"] [class*="list--x"] > li:nth-child( odd ) {
    clear: none;
  }
}
.header__link--cta {
  font-family: "muli", Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 700;
  background-color: #fff;
  border: 3px solid #177da4;
  color: #156d8e;
  border-radius: 0;
  text-transform: uppercase;
  transition: background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0.3125rem 0.625rem;
  text-decoration: none;
}
.header__link--cta:hover,
.header__link--cta:focus,
.header__link--cta.is-current {
  background-color: #f2f2f2;
  border-color: #115b77;
  color: #0e4b62;
}
/**
 * Responsive Breakpoints
 */
/**
 * Site
 */
/**
 * Base & Small Only
 */
/**
 * Small
 */
/**
 * Medium Only
 */
/**
 * Medium
 */
/**
 * Large
 */
/**
 * Extra Large
 */
/**
 * Print
 */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  html {
    font: 12pt/1.5em Georgia, "Times New Roman", Times, serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.1em;
    margin-bottom: 0.5em;
    margin-top: 1em;
    page-break-after: avoid;
    orphans: 3;
    widows: 3;
  }
  li h1,
  li h2,
  li h3,
  li h4,
  li h5,
  li h6 {
    margin-top: 0;
  }
  h1 {
    font-size: 24pt;
  }
  h2 {
    font-size: 21pt;
  }
  h3 {
    font-size: 18pt;
  }
  h4 {
    font-size: 16pt;
  }
  h5 {
    font-size: 14pt;
  }
  h6 {
    font-size: 12pt;
  }
  blockquote,
  p,
  ul,
  ol,
  dl,
  figure,
  img {
    margin-bottom: 1em;
  }
  figure img {
    margin-bottom: 0;
  }
  ul,
  ol,
  dd {
    margin-left: 3em;
  }
  pre,
  blockquote {
    border-left: 0.5em solid #999;
    padding-left: 1.5em;
    page-break-inside: avoid;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  figcaption {
    text-transform: uppercase;
    font-size: 10pt;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  form,
  video,
  nav {
    display: none !important;
  }
  @page {
    margin: 2cm;
  }
}
/*# sourceMappingURL=motif.css.map */